import Router from 'universal-router';
import routes from './routes';
import { isServerSide } from './core/util/system';
import { setSessionTrackingProperties } from './ducks/tracking';
import { SolvReduxState } from '~/reducers';

export type RedirectFunctionArgs = {
  request: Request;
  getFlag<T = boolean>(flagName: string): Promise<T | undefined>;
  isClient: boolean;
  getStore: () => SolvReduxState;
  clientContext?: {
    lastLocation: Location;
    newLocation: Location;
    action: 'PUSH' | 'POP' | 'REPLACE';
  };
};

export type RedirectFunctionReturn =
  | {
      type: 'external' | 'replace' | 'push';
      url: string;
    }
  | undefined;

export type RedirectFunction = (args: RedirectFunctionArgs) => Promise<RedirectFunctionReturn>;
export type RedirectRouteParam = RedirectFunction | string | undefined;

const options = {
  resolveRoute: (context: any, params: any) => {
    if (isServerSide()) {
      context.store.dispatch(setSessionTrackingProperties(context));
    }

    if (typeof context.route.load === 'function') {
      const exportName = context.route.exportName || 'default';
      return context.route.load(params).then((module: any) => module[exportName](context, params));
    }

    if (typeof context.route.get === 'function') {
      return context.route.get(context, params);
    }

    if (typeof context.route.action === 'function') {
      return context.route.action(context, params);
    }

    return undefined;
  },
};

export default new Router(routes, options);

if (module.hot) {
  module.hot.accept();
}
