import { takeEvery, call, put } from 'redux-saga/effects';
import { apiPost } from '../core/dapi';
import { getConsentFormSignaturesUrl } from '../core/dapi/consentFormSignatures';
import { consentFormSignaturesSuccess, consentFormSignaturesError } from '../ducks/paperwork';

export class ConsentFormSignaturesSagas {
  static CREATE_CONSENT_FORM_SIGNATURES = 'SAGA/CREATE_CONSENT_FORM_SIGNATURES';
}

function* createConsentFormSignaturesHelper({
  bookingId,
  locationConsentFormIds,
  signature,
  onSuccess,
}: any) {
  try {
    // @ts-expect-error ts-migrate(2769) FIXME: Type '(url: any, data: any, options: any) => any' ... Remove this comment to see the full error message
    yield call(apiPost, getConsentFormSignaturesUrl(), {
      booking_id: bookingId,
      location_consent_form_ids: locationConsentFormIds,
      signer_signature: signature,
    });
    yield put(consentFormSignaturesSuccess());
    onSuccess();
  } catch (e) {
    console.error(e);
    yield put(consentFormSignaturesError());
  }
}

export default function* createConsentFormSignatures() {
  yield takeEvery(
    ConsentFormSignaturesSagas.CREATE_CONSENT_FORM_SIGNATURES,
    createConsentFormSignaturesHelper
  );
}
