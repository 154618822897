import {
  ACCOUNT_HOME_VIEW,
  LOGGED_OUT_ABOUT_VIEW,
  LOGGED_OUT_FIND_CARE_VIEW,
  LOGGED_OUT_FOR_PROVIDERS_VIEW,
} from '~/components/Account/util/views';
import account from './account';
import addYourAvailability from './addYourAvailability';
import book, { redirectRemixBookingFlow } from './book';
import cdp from './cdp/routing';
import embed from './embed';
import group from './group/routing';
import handoff from './handoff';
import location from './location/routing';
import mobileCheckIn from './mobileCheckIn';
import now from './now';
import provider from './provider/routing';
import redir from './redir';
import videoVisit from './videoVisit';
import { RemixRoutes } from '~/core/remix';
import { queryStringFromObject } from '~/core/util/string';

const routes = {
  path: '',
  children: [
    {
      path: '',

      load: () => import(/* webpackChunkName: 'home' */ './home'),
      tabId: LOGGED_OUT_FIND_CARE_VIEW,
    },
    {
      path: '/go',
      children: [
        {
          path: '/search/:type',
          load: () => import(/* webpackChunkName: 'searchRouter' */ './searchRouter'),
          exportName: 'redirectToVideoSrp',
        },
      ],
    },
    {
      path: '/dfw',

      load: () => import(/* webpackChunkName: 'dfwHome' */ './dfw'),
    },
    {
      path: '/add-insurance/:origin',

      load: () => import(/* webpackChunkName: 'addInsurance' */ './addInsurance'),
    },
    {
      path: '/lab-results/:labResultId',
      load: () => import(/* webpackChunkName: 'labResults' */ './labResults'),
    },
    {
      path: '/lab-results-preview/:labResultConfigId',
      load: () => import(/* webpackChunkName: 'labResultsPreview' */ './labResults/preview'),
    },
    {
      path: '/add-insurance/:insuranceType/:origin',

      load: () => import(/* webpackChunkName: 'addInsurance' */ './addInsurance'),
    },

    {
      path: '/activate-feature-flag/:flag',

      load: () => import(/* webpackChunkName: 'activateFeatureFlag' */ './activateFeatureFlag'),
    },
    {
      path: '/search',
      load: () => import(/* webpackChunkName: 'searchRouter' */ './searchRouter'),
      exportName: 'search',
      tabId: ACCOUNT_HOME_VIEW,
    },
    {
      path: '/services',
      children: [
        {
          path: '',
          load: () => import(/* webpackChunkName: 'services' */ './services'),
          exportName: 'servicesIndex',
        },
        {
          path: '/:categorySlug',
          load: () => import(/* webpackChunkName: 'services' */ './services'),
          exportName: 'serviceTypePage',
        },
      ],
    },
    {
      path: '/appHome',
      children: [
        {
          path: '',
          load: () => import(/* webpackChunkName: 'appHome' */ './appHome'),
          exportName: 'loggedOutAccountHome',
        },
        {
          path: '/visits',
          load: () => import(/* webpackChunkName: 'appHome' */ './appHome'),
          exportName: 'loggedOutAccountHome',
        },
        {
          path: '/insurance',
          load: () => import(/* webpackChunkName: 'appHome' */ './appHome'),
          exportName: 'loggedOutAccountHome',
        },
        {
          path: '/profile',
          load: () => import(/* webpackChunkName: 'appHome' */ './appHome'),
          exportName: 'loggedOutAccountHome',
        },
      ],
    },
    {
      path: '/confirm',

      load: () => import(/* webpackChunkName: 'confirmation' */ './confirmation'),
      exportName: 'confirm',
    },
    {
      path: '/submitBooking',

      load: () => import(/* webpackChunkName: 'confirmation' */ './confirmation'),
      exportName: 'submitBooking',
    },
    {
      path: '/paymentMethod',
      load: () => import(/* webpackChunkName: 'paymentMethod' */ './paymentMethod'),
    },
    {
      path: '/finalize',

      load: () => import(/* webpackChunkName: 'confirmation' */ './confirmation'),
      exportName: 'finalize',
    },
    {
      // This is a booking flow
      path: '/profile',
      action: (context: any, params: any) => {
        return redirectRemixBookingFlow(context, params, 'directBooking');
      },
    },
    {
      path: '/add-family-member',

      load: () => import(/* webpackChunkName: 'addFamilyMember' */ './addFamilyMember'),
    },
    {
      path: '/symptoms',
      action() {
        return { redirect: '/search' };
      },
    },
    {
      path: '/pass/:id',

      load: () => import(/* webpackChunkName: 'pass' */ './pass'),
    },
    {
      path: '/c/:id',

      load: () => import(/* webpackChunkName: 'pass' */ './pass'),
      exportName: 'passConfirmationShortUrl',
    },
    {
      path: '/r/:id',

      load: () => import(/* webpackChunkName: 'pass' */ './pass'),
      exportName: 'passReminderShortUrl',
    },
    {
      path: '/visit-summary/:bookingId',
      load: () => import(/* webpackChunkName: 'visitSummary' */ './visitSummary'),
    },
    {
      path: '/vs/:bookingId',
      load: () => import(/* webpackChunkName: 'visitSummary' */ './visitSummary'),
      exportName: 'visitSummaryShortUrl',
    },
    {
      path: '/select-time/:nextPage(.*)',

      load: () => import(/* webpackChunkName: 'selectTime' */ './selectTime'),
    },
    {
      path: '/privacy',

      load: () => import(/* webpackChunkName: 'privacy' */ './privacy'),
    },
    {
      path: '/responsible-disclosure-policy',

      load: () => import(/* webpackChunkName: 'responsibleDisclosure' */ './responsibleDisclosure'),
    },
    {
      path: '/l/:locationId',

      load: () => import(/* webpackChunkName: 'waitList' */ './waitList'),
      exportName: 'locationWaitList',
    },
    {
      path: '/b/:bookingId',

      load: () => import(/* webpackChunkName: 'waitList' */ './waitList'),
      exportName: 'bookingWaitList',
    },
    {
      path: '/t/(.*)',

      load: () => import(/* webpackChunkName: 'tracking' */ './tracking'),
    },
    {
      path: '/feedback',

      load: () => import(/* webpackChunkName: 'feedback' */ './feedback'),
    },
    account,
    {
      path: '/sign-up',
      action() {
        return { redirect: '/account/login' };
      },
    },
    {
      path: '/login(.*)',

      load: () => import(/* webpackChunkName: 'login' */ './login'),
      exportName: 'redirectToAccount',
    },
    {
      path: '/logout',

      load: () => import(/* webpackChunkName: 'logout' */ './logout'),
    },
    {
      path: '/about-us',

      load: () => import(/* webpackChunkName: 'aboutUs' */ './aboutUs'),
    },
    {
      path: '/careers',
      children: [
        {
          path: '',

          load: () => import(/* webpackChunkName: 'careers' */ './careers'),
        },
      ],
    },
    {
      path: '/for-providers',

      load: () => import(/* webpackChunkName: 'forProviders' */ './forProviders'),
      tabId: LOGGED_OUT_FOR_PROVIDERS_VIEW,
    },
    {
      path: '/for-providers/telemedicine',

      load: () => import(/* webpackChunkName: 'telemedicine' */ './forProviders/telemedicine'),
      tabId: LOGGED_OUT_FOR_PROVIDERS_VIEW,
    },
    {
      path: '/book-online/confirm',
      load: () => import(/* webpackChunkName: 'bookingWidgetConfirm' */ './booking-widget/confirm'),
    },
    {
      path: '/book-online/:locationId',
      action(context: { params: { locationId: string }; req: any; query: Record<string, string> }) {
        const { locationId } = context.params;
        const qs = queryStringFromObject(context.query ?? {});
        const qsString = qs ? `?${qs}` : '';
        return {
          redirect: () => {
            return {
              url: RemixRoutes.bookingWidget(locationId, qsString),
              type: 'external',
            };
          },
        };
      },
    },
    {
      path: '/additional-patients/:origin',

      load: () => import(/* webpackChunkName: 'additionalPatients' */ './additionalPatients'),
    },
    {
      path: '/paperwork/:path/:id',
      action(context: { params: { id: string }; query: Record<string, string> }) {
        const bookingId = context.params.id;
        const origin = context.query?.origin;
        return {
          redirect: () => {
            return {
              url: RemixRoutes.paperwork(bookingId, origin ? `?origin=${origin}` : ''),
              type: 'external',
            };
          },
        };
      },
    },

    {
      path: '/payments',
      children: [
        {
          path: '/confirm/:invoiceId',

          load: () => import(/* webpackChunkName: 'payments' */ './payments'),
          exportName: 'confirmation',
        },
        {
          path: '/select/group/:groupId',

          load: () => import(/* webpackChunkName: 'payments' */ './payments'),
          exportName: 'select',
        },
        {
          path: '/select',

          load: () => import(/* webpackChunkName: 'payments' */ './payments'),
          exportName: 'select',
        },
        {
          path: '/select/(.*)',

          load: () => import(/* webpackChunkName: 'payments' */ './payments'),
          exportName: 'select',
        },
        {
          path: '/create-invoice',

          load: () => import(/* webpackChunkName: 'payments' */ './payments'),
          exportName: 'createInvoice',
        },
        {
          path: '/add-card',

          load: () => import(/*webpackChunkName: 'addCard' */ './payments'),
          exportName: 'addCard',
        },
        {
          path: '/add-card/r/:nextPage(.*)',

          load: () => import(/*webpackChunkName: 'addCardAndRedirect' */ './payments'),
          exportName: 'addCardAndRedirect',
        },
        {
          path: '/add-card-pre-visit/:nextPage(.*)',

          load: () => import(/*webpackChunkName: 'addCard' */ './payments'),
          exportName: 'addCardPreVisitTelemedFlow',
        },
        {
          path: '/add-card-bf/:nextPage(.*)',

          load: () => import(/*webpackChunkName: 'addCardDuringBooking' */ './payments'),
          exportName: 'addCardDuringBooking',
        },
        {
          path: '/redirect-to-bf',
          load: () => import(/*webpackChunkName: 'addCardDuringBooking' */ './payments'),
          exportName: 'redirectToBookingFlowAfterAddingCardExternally',
        },
        {
          path: '/:invoiceId',

          load: () => import(/* webpackChunkName: 'payments' */ './payments'),
          exportName: 'payments',
        },
      ],
    },
    {
      path: '/share/:source',

      load: () => import(/* webpackChunkName: 'share' */ './tracking/share'),
      exportName: 'shareTracking',
    },
    {
      path: '/add-review/:id',

      load: () => import(/* webpackChunkName: 'addReview' */ './addReview'),
    },
    {
      path: '/review-on-google/:bookingId',

      load: () => import(/* webpackChunkName: 'reviewOnGoogle' */ './reviewOnGoogle'),
    },
    {
      path: '/tos/:source',

      load: () => import(/* webpackChunkName: 'legal' */ './legal'),
    },
    {
      path: '/legal',
      tabId: LOGGED_OUT_ABOUT_VIEW,
      children: [
        {
          path: '/tos',

          load: () => import(/* webpackChunkName: 'legal' */ './legal'),
          exportName: 'solvTos',
          tabId: LOGGED_OUT_ABOUT_VIEW,
        },
        {
          path: '/telemed',

          load: () => import(/* webpackChunkName: 'legal' */ './legal'),
          exportName: 'telemedTos',
          tabId: LOGGED_OUT_ABOUT_VIEW,
        },
        {
          path: '/payments-disclosure',

          load: () => import(/* webpackChunkName: 'legal' */ './legal'),
          exportName: 'paymentsDisclosure',
          tabId: LOGGED_OUT_ABOUT_VIEW,
        },
      ],
    },
    {
      path: '/book-group/:groupId',

      load: () => import(/* webpackChunkName: 'bookGroup' */ './bookGroup'),
      exportName: 'bookGroup',
    },
    {
      path: '/book-provider/:groupId',

      load: () => import(/* webpackChunkName: 'bookGroup' */ './bookGroup'),
      exportName: 'bookProvider',
    },
    {
      path: '/photo-id-upload/:origin',

      load: () => import(/* webpackChunkName: 'photoIdUpload' */ './photoIdUpload'),
      exportName: 'photoIdUpload',
    },
    {
      path: '/visit/:bookingId',

      load: () => import(/* webpackChunkName: 'postVisitSummary' */ './postVisitSummary'),
      exportName: 'postVisitSummaryRedirectUrl',
    },
    addYourAvailability,
    videoVisit,
    mobileCheckIn,
    embed,
    redir,
    handoff,
    location,
    provider,
    group,
    now,
    book,
    {
      path: '/walk-in-clinic',

      load: () => import(/* webpackChunkName: 'loadingScreen' */ './loadingScreen'),
    },
    cdp,
    {
      path: '/:shit([a-zA-Z0-9]{5})/:notShit(.*)',
      action: require('./goDaddyShit').default,
    },
    {
      path: '/500',

      load: () => import(/* webpackChunkName: '500' */ './error'),
    },
    {
      path: '(.*)',

      load: () => import(/* webpackChunkName: '404' */ './404'),
    },
  ],

  async action({ next }: any) {
    return await next();
  },
};

export default routes;
