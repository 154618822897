// This is in alphabetical order. Please maintain that
export default [
  require('./abTesting').default,
  require('./account').default,
  require('./accountProfile').default,
  require('./addBackupTimes').default,
  require('./addCreditCard').default,
  require('./bigTexBooking').default,
  require('./bookGroup').default,
  require('./careers').default,
  require('./clinicLeads').default,
  require('./consentFormSignatures').default,
  require('./images').default,
  require('./insuranceCarrierPlans').default,
  require('./insuranceForm').default,
  require('./labResults').default,
  require('./leaveAReview').default,
  require('./locationConsentForms').default,
  require('./marketplaceInsurers').default,
  require('./nextAvailable').default,
  require('./paperwork').default,
  require('./physicalLocationsInGroup').default,
  require('./postVisitSummary').default,
  require('./providerGroups').default,
  require('./reminders').default,
  require('./search').default,
  require('./signUp').default,
  require('./solvRatings').default,
  require('./specialties').default,
  require('./universalSearch').default,
  require('./upcomingVisitActions').default,
  require('./userProfilePhotos').default,
  require('./verifyProfile').default,
  require('./videoVisit').default,
  require('./webPushSubscriptions').default,
];
