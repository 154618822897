import { takeEvery, call, put } from 'redux-saga/effects';
import { apiPost } from '../core/dapi';
import { getClinicLeadsUrl } from '../core/dapi/clinicLeads';
import { clinicLeadSuccess, clinicLeadError } from '../actions/clinicLead';

const SUBMIT_CLINIC_LEAD_TO_SLACK = 'saga/SUBMIT_CLINIC_LEAD_TO_SLACK';

function* submitClinicLeadToSlack({ form }: any) {
  try {
    const clinicLeadsUrl = getClinicLeadsUrl();

    // @ts-expect-error ts-migrate(2769) FIXME: Type '(url: any, data: any, options: any) => any' ... Remove this comment to see the full error message
    yield call(apiPost, clinicLeadsUrl, form);
    yield put(clinicLeadSuccess());
  } catch (e: any) {
    yield put(clinicLeadError(e.message));
  }
}

function* rootSaga() {
  yield takeEvery(SUBMIT_CLINIC_LEAD_TO_SLACK, submitClinicLeadToSlack);
}

export { SUBMIT_CLINIC_LEAD_TO_SLACK, rootSaga as default };
