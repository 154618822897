import { LDFlagSet } from 'launchdarkly-js-client-sdk';

let launchDarklyFlags: LDFlagSet | null = null;

/**
 * Loads initial launch darkly flag data from ssr
 *
 * @returns Object containing launch darkly flags for a user
 */
export function loadServerSideLaunchDarklyFlags(): LDFlagSet {
  if (launchDarklyFlags) return launchDarklyFlags;

  const lDFlagsSerialized = document.getElementById('ld-flags')?.dataset?.launchDarklyInitialFlags;
  try {
    // eslint-disable-next-line no-eval
    launchDarklyFlags = eval(`(${lDFlagsSerialized})`);
    return launchDarklyFlags as LDFlagSet;
  } catch (e) {
    console.warn('Unable to deserialize lDFlagsSerialized');
    console.warn(lDFlagsSerialized);
    return {};
  }
}
