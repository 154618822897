import React from 'react';

const route = '/handoff';

export default {
  path: route,
  get: () =>
    import(/* webpackChunkName: 'handoff' */ '../../components/Handoff').then((module) => {
      const Handoff = module.default;
      return {
        chunks: ['handoff'],

        component: <Handoff />,
        accountWrapper: {
          loginRequired: false,
          nonBlocking: true,
        },
      };
    }),
};
