/* eslint-disable no-bitwise */
import { Cookies } from 'react-cookie';
import { getLongTermSessionID } from '../session/longTermSessionID';
import { AB_TEST_GROUP_ASSIGNMENT } from '../analytics/events';
import { analyticsTrackEvent } from '../analytics';
import { apiGet } from '../dapi';
import { getAbTestingGroupAssignmentUrl } from '../dapi/abTesting';

const cookies = new Cookies();

// feel free to continue if you need more
export const A = 0;
export const B = 1;
export const C = 2;
export const D = 3;

const numericHash = (str: any) => {
  let hash = 0;
  let i;
  let chr;
  if (str.length === 0) return hash;
  for (i = 0; i < str.length; i++) {
    chr = str.charCodeAt(i);
    hash = (hash << 5) - hash + chr;
    hash |= 0;
  }

  return Math.abs(hash);
};

export const getTestGroupForIdHash = (idHash: any, numberOfGroups: any) =>
  numericHash(idHash) % numberOfGroups;

export const getTestGroupForLongTermSession = (numberOfGroups: any) =>
  numericHash(getLongTermSessionID()) % numberOfGroups;

export const hasUserAlreadyBeenAssignedToAbTestGroup = (abTestCookieName: any) =>
  Boolean(cookies.get(abTestCookieName));

export const isPartOfSpecificAbTestGroup = (assignedGroup: any, groupName: any) =>
  assignedGroup && assignedGroup === groupName;

export const setAbTestingCookie = (testName: any, cookieName: any, abTestGroupAssignment: any) => {
  cookies.set(cookieName, abTestGroupAssignment);
  analyticsTrackEvent(AB_TEST_GROUP_ASSIGNMENT, {
    abTestName: testName,
    group: abTestGroupAssignment,
  });
};

export const getAbTestingGroup = async (groupOptions = [], weights = []) => {
  const abTestGroupAssignment = await apiGet(getAbTestingGroupAssignmentUrl(groupOptions, weights));
  return abTestGroupAssignment;
};

export const getAbTestingGroupFromCookie = (cookies: any, cookieName: any) => {
  return cookies.get(cookieName);
};
