// Loosely adapted from src/core/isomorphic-style-loader/insertCss.ts

const inserted: { [moduleId: string]: string } = {};

/** Removes a style from the page.
 * // TODO: When is this called? */
export const removeCss = (moduleId: string) => {
  const elem = document.getElementById(moduleId);
  elem?.parentNode?.removeChild(elem);
};

/** Inserts CSS into the page in a <style> tag, checking a given moduleId to ensure it's not been
 * inserted previously */
export const insertCss = (css: string, moduleId: string) => {
  if (inserted[moduleId]) {
    return null;
  }

  const elem = document.createElement('style');
  elem.setAttribute('type', 'text/css');
  elem.id = moduleId;
  elem.textContent = css;

  document.head.appendChild(elem);

  return () => removeCss(moduleId);
};
