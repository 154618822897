import { takeEvery, call, put } from 'redux-saga/effects';
import { apiPost } from '../core/dapi';
import { getCareerLeadsUrl } from '../core/dapi/careerLeads';
import { careerFormSubmitting, careerFormReceived, careerFormError } from '../ducks/careers';

const SUBMIT_CAREER_FORM_TO_SLACK = 'saga/SUBMIT_CAREER_FORM_TO_SLACK';

function* submitCareerFormToSlack({ form, onSuccess, onFailure }: any) {
  try {
    yield put(careerFormSubmitting(true));
    const careerLeadsUrl = getCareerLeadsUrl();

    // @ts-expect-error ts-migrate(2769) FIXME: Type '(url: any, data: any, options: any) => any' ... Remove this comment to see the full error message
    const careerLeadsResponse = yield call(apiPost, careerLeadsUrl, form);
    yield put(careerFormReceived(careerLeadsResponse));

    onSuccess(careerLeadsResponse);
  } catch (e: any) {
    yield put(careerFormError(e.message));

    onFailure(e);
  } finally {
    yield put(careerFormSubmitting(false));
  }
}

function* rootSaga() {
  yield takeEvery(SUBMIT_CAREER_FORM_TO_SLACK, submitCareerFormToSlack);
}

export { SUBMIT_CAREER_FORM_TO_SLACK, rootSaga as default };
