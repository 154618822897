import React from 'react';
import { locationResponseFormatter } from '../../../../reducers/formatters/location';
import { getLocationUrl } from '../../../../core/dapi/location';
import { apiGet } from '../../../../core/dapi';
import { EMPTY_OBJECT } from '../../../../core/util/object';

export const NEXT_AVAILABLE = '/next-available';

export default {
  path: NEXT_AVAILABLE,
  get: ({ query }: any) =>
    import(/* webpackChunkName: 'nextAvailable' */ '../../../../components/NextAvailable').then(
      async (module) => {
        let err;

        const location = locationResponseFormatter(
          await apiGet(getLocationUrl(query.lid)).catch(() => {
            err = `Could not access Solv (${query.lid})`;
            return EMPTY_OBJECT;
          })
        );

        const NextAvailable = module.default;

        return {
          component: <NextAvailable err={err} ff={query.ff} location={location} />,
        };
      }
    ),
};
