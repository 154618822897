import moment from 'moment/moment';
import { RemixRoutes } from '~/core/remix';
import { queryStringFromObject } from '~/core/util/string';
import { getCurrentlySelectedLocationFromState } from '~/core/util/location';
import { getBooleanFlag } from '~/core/launch-darkly/flags';
import { isOnlineWaitlistBooking } from '~/core/util/booking';

export function redirectRemixBookingFlow(context: any, params: any, handlerName: string) {
  const state = context.store?.getState();
  const location = getCurrentlySelectedLocationFromState(state);
  const locationId = location?.id ?? params.locationId;

  function shouldRedirect() {
    if (location?.isPaymentDuringBookingEnabled) {
      return true;
    }
    return getBooleanFlag('marmotRemixBookingFlow');
  }

  if (!shouldRedirect()) {
    return import(/* webpackChunkName: 'bookingFlow' */ './bookingFlowRoute').then((r) => {
      return r[handlerName as keyof typeof r](context, params);
    });
  }

  const booking = state.newBooking?.booking;
  const isWaitList = booking ? isOnlineWaitlistBooking(booking) : false;
  // eslint-disable-next-line no-nested-ternary
  let slot = isWaitList
    ? 'online_waitlist'
    : booking?.appointmentTime
    ? moment(booking?.appointmentTime).toISOString()
    : '';
  return {
    redirect: () => {
      return {
        type: 'external',
        url: RemixRoutes.bookingWidget(
          locationId,
          `?${queryStringFromObject({
            slot,
            ...(booking?.visitTypeId ? { visitType: booking?.visitTypeId } : {}),
            variant: 'solv_attributed',
            ...state?.tracking?.campaign,
          })}`
        ),
      };
    },
  };
}

export default {
  path: '/book',
  children: [
    {
      path: '/direct/:locationId',
      action: (context: any, params: any) => {
        return redirectRemixBookingFlow(context, params, 'directBooking');
      },
    },
    {
      path: '/bf/:page(.*)',
      action: (context: any, params: any) => {
        return redirectRemixBookingFlow(context, params, 'router');
      },
    },
  ],
};
