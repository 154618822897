import React from 'react';
import { IntercomChat } from './intercomChat';
import { useLDFlags } from '~/core/launch-darkly/hooks/useLDFlags';

export const EnableIntercomChat = () => {
  const { plus673IsIntercomEnabled } = useLDFlags();

  if (plus673IsIntercomEnabled) {
    return <IntercomChat />;
  }

  return <></>;
};
