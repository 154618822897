export default (serviceWorkerPath: any) => {
  if ('serviceWorker' in navigator) {
    let forceReload = false;
    navigator.serviceWorker.addEventListener('message', (event) => {
      const { data } = event;

      if (data && data.type === 'versionCheck') {
        if (data.version !== 3) {
          forceReload = true;
        }
      }
    });

    const swRegistration = navigator.serviceWorker.register(serviceWorkerPath, {
      updateViaCache: 'imports',
    });

    return {
      swRegistration,
      onLocationChangeWrapper:
        (cb: any) =>
        // @ts-expect-error ts-migrate(7019) FIXME: Rest parameter 'args' implicitly has an 'any[]' ty... Remove this comment to see the full error message
        (...args) => {
          if (forceReload) {
            window.location.reload();
            return;
          }

          cb(...args);
        },
    };
  }

  return {
    swRegistration: Promise.reject(),
    onLocationChangeWrapper: (cb: any) => cb,
  };
};
