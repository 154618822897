import { takeEvery, call, put } from 'redux-saga/effects';
import { apiPost } from '../core/dapi';
import { getFoundHelpfulUrl } from '../core/dapi/solvRatings';
import { helpfulCountUpdating, helpfulCountUpdated, helpfulCountError } from '../ducks/solvRatings';

const INCREMENT_HELPFUL_COUNT = 'saga/INCREMENT_HELPFUL_COUNT';

function* incrementHelpfulCount({ solvRatingId }: any) {
  try {
    yield put(helpfulCountUpdating(solvRatingId, true));
    const foundHelpfulUrl = getFoundHelpfulUrl(solvRatingId);

    // @ts-expect-error ts-migrate(2769) FIXME: Type '(url: any, data: any, options: any) => any' ... Remove this comment to see the full error message
    const updatedSolvRating = yield call(apiPost, foundHelpfulUrl, {});
    yield put(helpfulCountUpdated(solvRatingId, updatedSolvRating.helpful_count));
  } catch (e: any) {
    yield put(helpfulCountError(solvRatingId, e.message));
  } finally {
    yield put(helpfulCountUpdating(solvRatingId, false));
  }
}

function* rootSaga() {
  yield takeEvery(INCREMENT_HELPFUL_COUNT, incrementHelpfulCount);
}

export { INCREMENT_HELPFUL_COUNT, rootSaga as default };
