/* eslint-disable react/no-unused-state */
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { withCookies } from 'react-cookie';
import AuthenticationContext from './AuthenticationContext';
import { getLoginInfo } from '../session';
import { isEmptyObject } from '../util/object';
import { LOGIN_INFO_COOKIE_NAME } from '../../config/index';
import { isEmptyArray } from '../util/array';
import {
  getIsStatusUnverifiable,
  VerificationStatus,
} from '../../components/PhoneLoginForm/util/verificationStatus';

const mapStateToProps = (state: any) => ({
  accountSummary: state.account.summary,
  loginAccount: state.login.account,
  verificationStatus: state.runtime.phoneVerificationStatus,
});

export type AuthProviderProps = {
  cookies: any;
  accountSummary?: any;
  loginAccount?: any;
  verificationStatus: VerificationStatus;
};

export type AuthContext = {
  isAuthorized: boolean;
  isLoggedIn: boolean;
};

class AuthProvider extends PureComponent<AuthProviderProps, AuthContext> {
  constructor(props: AuthProviderProps) {
    super(props);
    this.state = {
      isAuthorized: !!getLoginInfo(props.cookies), // has a token to access account data
      isLoggedIn: this.hasAllAccountData(
        props.loginAccount,
        props.accountSummary,
        props.verificationStatus
      ), // has loaded account data
    };
  }

  componentDidMount = () => {
    const { cookies } = this.props;
    cookies && cookies.addChangeListener(this.onCookiesChange);
  };

  componentDidUpdate = (prevProps: AuthProviderProps) => {
    if (this.didJustLogIn(prevProps)) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ isLoggedIn: true });
    } else if (this.didJustLogOut(prevProps)) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ isLoggedIn: false });
    }
  };

  onCookiesChange = ({ name, value, options }: any) => {
    if (name === LOGIN_INFO_COOKIE_NAME) {
      if (!isEmptyObject(value)) {
        return this.setState({ isAuthorized: true });
      }
      return this.setState({ isAuthorized: false });
    }
    return null;
  };

  didJustLogIn = (prevProps: AuthProviderProps) =>
    !this.hasAllAccountData(
      prevProps.loginAccount,
      prevProps.accountSummary,
      prevProps.verificationStatus
    ) &&
    this.hasAllAccountData(
      this.props.loginAccount,
      this.props.accountSummary,
      this.props.verificationStatus
    );

  // not checking against accountSummary because that does not get persisted between page refreshes
  didJustLogOut = (prevProps: any) =>
    !isEmptyObject(prevProps.loginAccount) && isEmptyObject(this.props.loginAccount);

  hasAllAccountData = (loginAccount: any, accountSummary: any, verificationStatus: any) => {
    return (
      !isEmptyObject(loginAccount) &&
      !isEmptyObject(accountSummary) &&
      !isEmptyArray(accountSummary.user_profiles) &&
      (isEmptyObject(verificationStatus) || !getIsStatusUnverifiable(verificationStatus))
    );
  };

  render = () => {
    const { children } = this.props;
    return (
      <AuthenticationContext.Provider value={this.state}>{children}</AuthenticationContext.Provider>
    );
  };
}

export { AuthProvider };

export default connect(mapStateToProps)(withCookies(AuthProvider));
