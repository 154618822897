import { ACUANT_API_KEY, ACUANT_BASE_URL } from '../../config/index';
import { emptyInteractions } from '../util/rcForm/rcFormToReduxForm';
import { mapToStandardInsurerType } from '../util/insurance';
import { isEmptyString } from '../util/string';
import { getFieldObject } from '../util/rcForm/rcFormToReduxForm';

const INSURER_TYPE_FIELD_NAME = 'insurerType';
const MEMBER_CODE_FIELD_NAME = 'memberCode';
const INSURER_CODE_FIELD_NAME = 'insurerCode';
const acuantCropUrl = `${ACUANT_BASE_URL}/True/0/200/True`;
const acuantNoCropUrl = `${ACUANT_BASE_URL}/False/0/200/False`;

const ACUANT_WEB_DESCRIPTIONS = {
  unableToCrop: 'Not able to crop the image.',
};

const RESPONSE_CODE_PROC_MEDICAL_CARD_DESC = {
  EXTRACTION_FAILED: 'Text Extraction Failed',
};

const buildAcuantData = (fileData: any) => {
  const data = new FormData();
  data.append('image', fileData);
  return data;
};

const acuantReformattedResponseToBase64 = (imgArray: any) => {
  const imgBuffer = Buffer.from(imgArray);
  return `data:image/jpg;base64,${imgBuffer.toString('base64')}`;
};

const mapAcuantInsurerToStandardType = (fieldData: any) => {
  let insurerType = null;
  if (!isEmptyString(fieldData.insurerType)) {
    insurerType = mapToStandardInsurerType(fieldData.insurerType.toLowerCase());
  }

  if (isEmptyString(insurerType) && !isEmptyString(fieldData.rawText)) {
    insurerType = mapToStandardInsurerType(fieldData.rawText.toLowerCase());
  }

  return insurerType;
};

const acuantPostImage = (url: any, postData: any) => {
  const options = {
    method: 'POST',
    headers: { Authorization: ACUANT_API_KEY },
    body: postData,
  };

  // @ts-ignore
  return fetch(url, options).then((req) => req.json());
};

const guessInsuranceCarrier = (response: any) => {
  if (response.PlanProvider === 'Blue Cross Blue Shield') {
    // Let's see if we can get more specific that this for california and texas
    if (response.RawText && typeof response.RawText === 'string') {
      const rawLowered = response.RawText.toLowerCase();
      if (rawLowered.indexOf('california') !== -1) {
        return 'Blue Shield of California';
      }

      if (rawLowered.indexOf('texas') !== -1) {
        if (rawLowered.indexOf('medicaid') !== -1) {
          return 'Blue Cross Blue Shield Texas Medicaid';
        }
        return 'Blue Cross Blue Shield of Texas';
      }
    }
  }

  return response.PlanProvider;
};

const mapAcuantToFields = (response: any) => ({
  firstName: response.FirstName,
  lastName: response.LastName,
  insuranceCarrier: guessInsuranceCarrier(response),
  memberId: response.MemberId,
  insuranceType: response.PlanType,
  rawText: response.RawText,
});

const mapAcuantToFieldsDepreciated = (response: any) => ({
  firstName: response.FirstName,
  lastName: response.LastName,
  insurerCode: response.PlanProvider,
  memberCode: response.MemberId,
  insurerType: response.PlanType,
  rawText: response.RawText,
});

export {
  buildAcuantData,
  acuantPostImage,
  mapAcuantToFields,
  mapAcuantToFieldsDepreciated,
  acuantReformattedResponseToBase64,
  mapAcuantInsurerToStandardType,
  ACUANT_WEB_DESCRIPTIONS,
  RESPONSE_CODE_PROC_MEDICAL_CARD_DESC,
  acuantCropUrl,
  acuantNoCropUrl,
};
