export default {
  path: '/solvnow',
  children: [
    {
      path: '',
      load: () => import(/* webpackChunkName: 'now' */ './routing'),
      exportName: 'nowLanding',
    },
  ],
};
