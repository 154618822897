import React from 'react';
import { WhiteLabelWrapper } from '~/components/util/WhiteLabelWrapper';

export default {
  path: '/video-visit',
  children: [
    {
      path: '/:bookingId/post-visit',
      get: (context: any) =>
        import(
          /* webpackChunkName: 'videoVisit' */ '../../components/VideoVisit/components/VisitComplete'
        ).then(({ default: PostVisit }) => ({
          chunks: ['videoVisit'],
          component: (
            <WhiteLabelWrapper bookingId={context.params.bookingId}>
              <PostVisit bookingId={context.params.bookingId} />
            </WhiteLabelWrapper>
          ),
          accountWrapper: {
            returnAfterLogin: false,
            loginRequired: false,
          },
        })),
    },
    {
      path: '/:bookingId/pre-visit-paperwork',
      get: (context: any) =>
        import(
          /* webpackChunkName: 'videoVisit' */ '../../components/VideoVisit/ConnectedPreVisitPaperwork'
        ).then((module) => {
          const ConnectedVideoVisit = module.default;
          return {
            chunks: ['videoVisit'],
            component: (
              <WhiteLabelWrapper bookingId={context.params.bookingId}>
                <ConnectedVideoVisit
                  bookingId={context.params.bookingId}
                  hasEnded={context.query?.ended ?? false}
                  shouldAutoJoin={context.query?.autoJoin ?? false}
                />
              </WhiteLabelWrapper>
            ),
            accountWrapper: {
              returnAfterLogin: true,
              loginRequired: true,
            },
          };
        }),
    },
    {
      path: '/:bookingId/pre-visit-photo-id-upload',
      get: (context: any) =>
        import(
          /* webpackChunkName: 'videoVisit' */ '../../components/VideoVisit/ConnectedPreVisitPhotoIdUpload'
        ).then((module) => {
          const ConnectedVideoVisit = module.default;
          return {
            chunks: ['videoVisit'],
            component: (
              <WhiteLabelWrapper bookingId={context.params.bookingId}>
                <ConnectedVideoVisit
                  bookingId={context.params.bookingId}
                  hasEnded={context.query?.ended ?? false}
                  shouldAutoJoin={context.query?.autoJoin ?? false}
                />
              </WhiteLabelWrapper>
            ),
            accountWrapper: {
              returnAfterLogin: true,
              loginRequired: true,
            },
          };
        }),
    },
    {
      path: '/:bookingId/pre-visit-payment',
      get: (context: any) =>
        import(
          /* webpackChunkName: 'videoVisit' */ '../../components/VideoVisit/ConnectedPreVisitPayment'
        ).then((module) => {
          const ConnectedVideoVisit = module.default;
          return {
            chunks: ['videoVisit'],
            component: (
              <WhiteLabelWrapper bookingId={context.params.bookingId}>
                <ConnectedVideoVisit
                  bookingId={context.params.bookingId}
                  hasEnded={context.query?.ended ?? false}
                  shouldAutoJoin={context.query?.autoJoin ?? false}
                />
              </WhiteLabelWrapper>
            ),
            accountWrapper: {
              returnAfterLogin: true,
              loginRequired: true,
            },
          };
        }),
    },
    {
      path: '/:bookingId/pre-visit-ready',
      get: (context: any) =>
        import(
          /* webpackChunkName: 'videoVisit' */ '../../components/VideoVisit/ConnectedPreVisitReady'
        ).then((module) => {
          const ConnectedVideoVisit = module.default;
          return {
            chunks: ['videoVisit'],
            component: (
              <WhiteLabelWrapper bookingId={context.params.bookingId}>
                <ConnectedVideoVisit
                  bookingId={context.params.bookingId}
                  hasEnded={context.query?.ended ?? false}
                  shouldAutoJoin={context.query?.autoJoin ?? false}
                />
              </WhiteLabelWrapper>
            ),
            accountWrapper: {
              returnAfterLogin: true,
              loginRequired: true,
            },
          };
        }),
    },
    {
      path: '/:bookingId/rl',
      get: (context: any) =>
        import(
          /* webpackChunkName: 'videoVisit' */ '../../components/VideoVisit/ConnectedVideoVisit'
        ).then((module) => {
          const ConnectedVideoVisit = module.default;
          return {
            chunks: ['videoVisit'],
            component: (
              <WhiteLabelWrapper bookingId={context.params.bookingId}>
                <ConnectedVideoVisit
                  bookingId={context.params.bookingId}
                  hasEnded={context.query?.ended ?? false}
                  shouldAutoJoin={context.query?.autoJoin ?? false}
                />
              </WhiteLabelWrapper>
            ),
            accountWrapper: {
              returnAfterLogin: true,
              loginRequired: true,
            },
          };
        }),
    },
    {
      path: '/:bookingId',
      get: (context: any) =>
        import(
          /* webpackChunkName: 'videoVisit' */ '../../components/VideoVisit/ConnectedVideoVisit'
        ).then((module) => {
          const ConnectedVideoVisit = module.default;
          return {
            chunks: ['videoVisit'],
            component: (
              <WhiteLabelWrapper bookingId={context.params.bookingId}>
                <ConnectedVideoVisit
                  bookingId={context.params.bookingId}
                  hasEnded={context.query?.ended ?? false}
                  shouldAutoJoin={context.query?.autoJoin ?? false}
                />
              </WhiteLabelWrapper>
            ),
            accountWrapper: {
              returnAfterLogin: true,
              loginRequired: false,
            },
          };
        }),
    },
  ],
};

if (module.hot) {
  module.hot.accept();
}
