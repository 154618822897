import { takeEvery, call, put } from 'redux-saga/effects';
import { hasUserAlreadyBeenAssignedToAbTestGroup, setAbTestingCookie } from '../core/util/abTest';
import {
  abTestingGroupFetchingAssignment,
  abTestingGroupAssignmentError,
  abTestingGroupAssignmentReceived,
} from '../ducks/abTests';
import { getAbTestingGroupAssignmentUrl } from '../core/dapi/abTesting';
import { apiGet } from '../core/dapi';
import { AbTestGroupDefaults } from '../constants/abTesting';

export const ASSIGN_TO_AB_TESTING_GROUP = 'saga/ASSIGN_TO_AB_TESTING_GROUP';

export function* assignToAbTestingGroup(
  testName: any,
  cookieName: any,
  groupOptions = [],
  weights = []
): any {
  if (!hasUserAlreadyBeenAssignedToAbTestGroup(cookieName)) {
    try {
      yield put(abTestingGroupFetchingAssignment(true));

      const abTestGroupAssignment = yield call(
        apiGet,
        getAbTestingGroupAssignmentUrl(groupOptions, weights)
      );

      yield put(abTestingGroupAssignmentReceived(testName, abTestGroupAssignment));
      setAbTestingCookie(testName, cookieName, abTestGroupAssignment);
      yield put(abTestingGroupFetchingAssignment(false));
    } catch (e) {
      yield put(abTestingGroupAssignmentError(testName, e));
      setAbTestingCookie(testName, cookieName, AbTestGroupDefaults.CONTROL);
      yield put(abTestingGroupFetchingAssignment(false));
    }
  }
}

function* rootSaga() {
  // @ts-expect-error ts-migrate(2769) FIXME: Argument of type 'string' is not assignable to par... Remove this comment to see the full error message
  yield takeEvery(ASSIGN_TO_AB_TESTING_GROUP, assignToAbTestingGroup);
}

export { rootSaga as default };
