import I18nextBrowserLanguageDetector from 'i18next-browser-languagedetector';
import I18NextHttpBackend from 'i18next-http-backend';
import i18n, { init, options } from './i18n';

/**
 * Loads i18next on the client
 *
 * @returns A promise which resolves once i18next is loaded
 */
export async function loadClientI18next() {
  if (i18n.isInitialized) return true;

  i18n.use(I18nextBrowserLanguageDetector);
  return await init(options, I18NextHttpBackend);
}

export default i18n;
