const ACCOUNT_FILE_UPLOAD = 'account/FILE_UPLOAD';

// actions
export const accountFileUpload = (value: any) => ({
  type: ACCOUNT_FILE_UPLOAD,
  payload: { value },
});

// reducers
export default (state = { status: '' }, action: any) => {
  switch (action.type) {
    case ACCOUNT_FILE_UPLOAD:
      return {
        ...state,
        [action.payload.value.bookingId]: { ...action.payload.value },
      };
    default:
      return state;
  }
};
