import v1 from './v1';

export const EMBED = '/embed';

export default {
  path: EMBED,
  children: [v1],
  accountWrapper: {
    loginRequired: false,
    nonBlocking: true,
  },
};
