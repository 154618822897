import isEmpty from 'lodash/isEmpty';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useCookies } from 'react-cookie';
import { useDispatch, useSelector } from 'react-redux';
import { setRuntimeVariable } from '~/actions/runtime';
import { useIntercomScript } from '~/core/intercom/hooks/useIntercomScript';
import { useAuthentication } from '~/core/login/AuthenticationContext';
import useRuntimeVariable from '~/hooks/runtime/useRuntimeVariable';
import useSelectActiveUserProfile from '~/hooks/useSelectActiveUserProfile';
import { INTERCOM_APP_ID, LOGIN_INFO_COOKIE_NAME } from '../../config';

type IntercomUserProperties = {
  email: string;
  phone: string;
  name: string;
  user_id: string;
  user_hash: string;
  hide_default_launcher?: boolean;
  [key: string]: string | boolean | undefined;
};

/**
 * Intercom
 * https://developers.intercom.com/installing-intercom/docs/intercom-javascript
 **/
export const Intercom = {
  getInstance: () => {
    const w = window as any;
    return w.Intercom;
  },
  isLoaded: () => {
    return !isEmpty(Intercom.getInstance());
  },
  boot: (properties: IntercomUserProperties) => {
    Intercom.getInstance()('boot', {
      app_id: INTERCOM_APP_ID,
      ...properties,
    });
  },
  isBooted: () => {
    return Intercom.getInstance()?.booted === true;
  },
  update: (properties: IntercomUserProperties) => {
    Intercom.getInstance()('update', {
      app_id: INTERCOM_APP_ID,
      ...properties,
    });
  },
  // shows the intercom messenger panel
  show: (properties: IntercomUserProperties) => {
    Intercom.getInstance()('show', {
      app_id: INTERCOM_APP_ID,
      ...properties,
    });
  },
  // hides the intercom messenger panel
  hide: () => {
    Intercom.getInstance()('hide');
  },
  // clears cookies, conversations, and any user data shared via the JS API. use upon logout.
  shutdown: () => {
    const w = window as any;
    if (typeof w.Intercom === 'function') {
      w.Intercom('shutdown');
    }
  },
  registerOnHideCallback: (dispatchFn: Function, setRuntime: Function) => {
    Intercom.getInstance()('onHide', () => {
      dispatchFn(setRuntime({ name: 'showConciergeChat', value: false }));
    });
  },
};

/**
 * Loads and updates user properties for intercom chat; shows message panel upon load.
 *
 * @returns null
 */
export function IntercomChat() {
  const [shouldLoadScript, setShouldLoadScript] = useState(false);
  const [scriptLoaded, setScriptLoaded] = useState(false);
  const [hash, setHash] = useState('');
  const accountSummary = useSelector((state) => state?.account?.summary);
  const currentProfile = useSelectActiveUserProfile();
  const { first_name = '', last_name = '', relationship_to_account = '' } = currentProfile ?? {};
  const { isLoggedIn } = useAuthentication();
  const [showConciergeChat] = useRuntimeVariable('showConciergeChat', false);
  const { id = '', email = '', phone = '' } = accountSummary ?? {};

  const dispatch = useDispatch();

  const [cookies] = useCookies([LOGIN_INFO_COOKIE_NAME]);
  const cookie = cookies[LOGIN_INFO_COOKIE_NAME];

  const fetchData = useCallback(async () => {
    const result = await fetch(`/intercom/hash/${id}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${cookie?.authToken}`,
      },
    });
    return await result.json();
  }, [id, cookie?.authToken]);

  const properties = useMemo(() => {
    return {
      email,
      phone,
      name: `${first_name} ${last_name}`,
      user_id: id,
      user_hash: hash,
      relationship_to_account,
      hide_default_launcher: true,
    };
  }, [email, first_name, hash, id, last_name, phone, relationship_to_account]);

  useEffect(() => {
    if (!isEmpty(id) && isLoggedIn && !isEmpty(cookie?.authToken)) {
      const result = fetchData();
      result.then((newResult) => setHash(newResult.hash));
    }
  }, [id, isLoggedIn, cookie?.authToken, fetchData]);

  useEffect(() => {
    if (!scriptLoaded || !hash) {
      return;
    }
    try {
      if (!Intercom.isBooted()) {
        Intercom.boot(properties);
        Intercom.registerOnHideCallback(dispatch, setRuntimeVariable);
      } else {
        // This else handles the case where a user changes profiles.
        // Previous was calling Intercom.update but it would not always
        // Update reliably. If we call shutdown then boot, it reliably
        // switches profiles.
        Intercom.shutdown();
        Intercom.boot(properties);
        Intercom.registerOnHideCallback(dispatch, setRuntimeVariable);
      }
    } catch (e) {
      console.error('Failed to boot or update intercom', e);
    }
  }, [hash, scriptLoaded, properties, dispatch]);

  useEffect(() => {
    if (!Intercom.isLoaded()) {
      return;
    }
    if (!isLoggedIn) {
      Intercom.shutdown();
    }
  }, [isLoggedIn]);

  useEffect(() => {
    if (id && !isEmpty(hash)) {
      setShouldLoadScript(true);
    }
  }, [id, hash]);

  useEffect(() => {
    if (!Intercom.isLoaded()) {
      return;
    }
    if (!showConciergeChat) {
      Intercom.hide();
      Intercom.update(properties);
    } else {
      Intercom.boot(properties);
      Intercom.registerOnHideCallback(dispatch, setRuntimeVariable);
      Intercom.show(properties);
    }
  }, [showConciergeChat, properties, dispatch]);

  const onLoad = () => {
    if (shouldLoadScript) {
      if (Intercom.getInstance() != null) {
        setScriptLoaded(true);
      }
    }
  };

  useIntercomScript(onLoad, INTERCOM_APP_ID || '');

  return null;
}
