import React from 'react';
import AccountViews from '../../components/AccountGetsMarried/constants/AccountViews';

export default {
  path: '/account',
  children: [
    {
      path: '/login',
      children: [
        {
          path: '',

          load: () => import(/* webpackChunkName: 'login' */ './../login'),
          exportName: 'symptomsFlowLogin',
        },
        {
          path: '/next/payments',
          children: [
            {
              path: '/:invoiceId',

              load: () => import(/* webpackChunkName: 'login' */ './../login'),
              exportName: 'paymentsFlowLogin',
            },
            {
              path: '/:invoiceId/:locationId',

              load: () => import(/* webpackChunkName: 'login' */ './../login'),
              exportName: 'paymentsFlowLogin',
            },
            {
              path: '/select',
              children: [
                {
                  path: '/group/:groupId',

                  load: () => import(/* webpackChunkName: 'login' */ './../login'),
                  exportName: 'paymentsSelectLogin',
                },
                {
                  path: '/location/:locationId',

                  load: () => import(/* webpackChunkName: 'login' */ './../login'),
                  exportName: 'paymentsSelectLogin',
                },
              ],
            },
          ],
        },
        {
          path: '/next/(.*)',

          load: () => import(/* webpackChunkName: 'login' */ './../login'),
          exportName: 'loginThenRedirect',
        },
        {
          path: '/remix/(.*)',

          load: () => import(/* webpackChunkName: 'login' */ './../login'),
          exportName: 'loginThenRedirectRemix',
        },
        {
          path: '/book-online/:locationId',

          load: () => import(/* webpackChunkName: 'login' */ './../login'),
          exportName: 'bookingWidgetFlowLogin',
        },
        {
          path: '/payments',
          children: [
            {
              path: '/:invoiceId',

              load: () => import(/* webpackChunkName: 'login' */ './../login'),
              exportName: 'paymentsFlowLogin',
            },
            {
              path: '/:invoiceId/:locationId',

              load: () => import(/* webpackChunkName: 'login' */ './../login'),
              exportName: 'paymentsFlowLogin',
            },
            {
              path: '/select',
              children: [
                {
                  path: '/group/:groupId',

                  load: () => import(/* webpackChunkName: 'login' */ './../login'),
                  exportName: 'paymentsSelectLogin',
                },
                {
                  path: '/location/:locationId',

                  load: () => import(/* webpackChunkName: 'login' */ './../login'),
                  exportName: 'paymentsSelectLogin',
                },
              ],
            },
          ],
        },
        {
          path: '/payments/location/:locationId',

          load: () => import(/* webpackChunkName: 'login' */ './../login'),
          exportName: 'paymentsLocationLogin',
        },
        {
          path: '/payments/group/:groupId',

          load: () => import(/* webpackChunkName: 'login' */ './../login'),
          exportName: 'paymentsLocationLogin',
        },
        {
          path: '/b/:bookingId',

          load: () => import(/* webpackChunkName: 'login' */ './../login'),
          exportName: 'generalLoginFlow',
        },
        {
          path: '/account',
          children: [
            {
              path: '/confirm/:bookingId',

              load: () => import(/* webpackChunkName: 'login' */ './../login'),
              exportName: 'waitListFlowLogin',
            },
            {
              path: '/people/:userProfileId',

              load: () => import(/* webpackChunkName: 'login' */ './../login'),
              exportName: 'confirmationLogin',
            },
            {
              path: '/people/:userProfileId/profile',

              load: () => import(/* webpackChunkName: 'login' */ './../login'),
              exportName: 'addPreferredPharmacyLogin',
            },
            {
              path: '/welcome/:userProfileId',

              load: () => import(/* webpackChunkName: 'login' */ './../login'),
              exportName: 'generalLoginFlow',
            },
          ],
        },
      ],
    },
    {
      // TODO-AW: check that this still works
      path: '/book-for-user/:userProfileId',

      load: () => import(/* webpackChunkName: 'cdp2' */ './../cdp/index'),
    },
    {
      path: '/profile/create',
      load: () => import(/* webpackChunkName: 'createProfile' */ './createProfile/index'),
      exportName: 'default',
    },
    {
      path: '/home',
      children: [
        {
          path: '',

          load: () => import(/* webpackChunkName: 'accountGetsMarried' */ './home'),
          exportName: 'accountGetsMarried',
          view: 'None',
        },
        {
          path: '/:userProfileId',

          load: () => import(/* webpackChunkName: 'accountGetsMarried' */ './home'),
          exportName: 'accountGetsMarried',
          view: 'None',
        },
        {
          path: '/:userProfileId/get-care',

          load: () => import(/* webpackChunkName: 'accountGetsMarried' */ './home'),
          exportName: 'accountGetsMarried',
          view: AccountViews.GetCare,
        },
        {
          path: '/:userProfileId/manage',

          load: () => import(/* webpackChunkName: 'accountGetsMarried' */ './home'),
          exportName: 'accountGetsMarried',
          view: AccountViews.Manage,
        },
        {
          path: '/:userProfileId/insurance',

          load: () => import(/* webpackChunkName: 'accountGetsMarried' */ './home'),
          exportName: 'accountGetsMarried',
          view: AccountViews.Insurance,
        },
        {
          path: '/:userProfileId/profile',

          load: () => import(/* webpackChunkName: 'accountGetsMarried' */ './home'),
          exportName: 'accountGetsMarried',
          view: AccountViews.Profile,
        },
      ],
    },
    {
      path: '/care-team',
      load: () => import(/* webpackChunkName: 'careTeam' */ './../account/careTeam/routing'),
      exportName: 'careTeam',
    },
    {
      path: '/care-options/:type',
      load: () => import(/* webpackChunkName: 'careTeam' */ './../account/careTeam/routing'),
      exportName: 'careOptions',
    },
    {
      path: '/people',
      children: [
        {
          path: '',

          load: () => import(/* webpackChunkName: 'accountGetsMarried' */ './../account/people'),
          exportName: 'redirectPeopleUrls',
        },
        {
          path: '/:userProfileId',

          load: () => import(/* webpackChunkName: 'accountGetsMarried' */ './../account/people'),
          exportName: 'redirectPeopleUrls',
        },
        {
          path: '/:userProfileId/:accountPart',

          load: () => import(/* webpackChunkName: 'accountGetsMarried' */ './../account/people'),
          exportName: 'redirectPeopleUrls',
        },
        {
          path: '/:userProfileId/visit/:bookingId',

          load: () => import(/* webpackChunkName: 'accountGetsMarried' */ './../account/people'),
          exportName: 'redirectPeopleUrls',
        },
        {
          path: `/:userProfileId/:accountPart/:categoryId/:tileId`,

          load: () => import(/*webpackChunkName: 'accountGetsMarried' */ './../account/people'),
          exportName: 'redirectPeopleUrls',
        },
        {
          path: `/:userProfileId/:accountPart/:secondaryTab`,

          load: () => import(/*webpackChunkName: 'accountGetsMarried' */ './../account/people'),
          exportName: 'redirectPeopleUrls',
        },
      ],
    },
    {
      path: '/settings',

      load: () => import(/* webpackChunkName: 'accountGetsMarried' */ './home'),
      exportName: 'accountGetsMarried',
      view: 'settings',
    },
    {
      path: '/welcome/:userProfileId',

      load: () => import(/* webpackChunkName: 'accountWelcome' */ './../account/welcome'),
      exportName: 'welcomeToSolvFlow',
    },
    {
      path: '/pass/:id',

      load: () => import(/* webpackChunkName: 'pass' */ './../pass'),
      exportName: 'modalPass',
    },
    {
      path: '/waitlist/:bookingId',

      load: () => import(/* webpackChunkName: 'waitList' */ './../waitList'),
      exportName: 'accountWaitList',
    },
    {
      path: '/payments/confirm/:invoiceId',

      load: () => import(/* webpackChunkName: 'payments' */ './../payments'),
      exportName: 'modalConfirmation',
    },
    {
      path: '/payments/:invoiceId',

      load: () => import(/* webpackChunkName: 'payments' */ './../payments'),
      exportName: 'modalPayments',
    },
    {
      path: '/add-review/:bookingId',

      load: () => import(/* webpackChunkName: 'addReview' */ './../addReview'),
      exportName: 'modalReview',
    },
    {
      path: '/t/(.*)',

      load: () => import(/* webpackChunkName: 'tracking' */ './../tracking'),
    },
  ],
};
