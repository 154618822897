import { Cookies } from 'react-cookie';
import isEmpty from 'lodash/isEmpty';
import { CookieNames, URL_PARAMS_HISTORY_COOKIE_NAME } from '../../config/index';

const cookies = new Cookies();

const deleteUrlParamsCookie = () => {
  // delete the cookie since it's ddos friendly
  // Get (if any) existing url params from history cookie and append the current ones
  const urlParametersHistory = cookies.get(URL_PARAMS_HISTORY_COOKIE_NAME) || [];

  if (urlParametersHistory && urlParametersHistory.length) {
    // delete the cookie since it's ddos friendly
    cookies.remove(URL_PARAMS_HISTORY_COOKIE_NAME, {
      // @ts-ignore
      expires: new Date(new Date().setYear(2099)),
      path: '/',
    });
  }
};

const deleteMixpanelCookie = () => {
  // this cookie was getting too large for some users, causing 431 error
  const mixpanelCookie = cookies.get(CookieNames.MIXPANEL);
  if (!isEmpty(mixpanelCookie)) {
    cookies.remove(CookieNames.MIXPANEL);
  }
};

export { deleteMixpanelCookie, deleteUrlParamsCookie };
