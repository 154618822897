import { useSSR } from 'react-i18next';
import { isClientSide } from '../core/util/system';

let deserializedI18nStore: any | null = null;

/**
 * Loads i18n ssr data from ssr
 *
 * @returns Object containing i18n store
 */
function loadI18nData() {
  if (deserializedI18nStore) return deserializedI18nStore;

  const i18nStoreRaw = document.getElementById('i18n-ssr')?.dataset.initialI18nStore;
  try {
    // eslint-disable-next-line no-eval
    deserializedI18nStore = eval(`(${i18nStoreRaw})`);
    return deserializedI18nStore;
  } catch (e) {
    console.warn('Unable to deserialize i18n store');
    console.warn(i18nStoreRaw);
    return {};
  }
}

/**
 * Initialized i18n translation keys from values sent via SSR
 */
export function useI18nSsr() {
  loadI18nData();
  const initialLanguage = isClientSide() ? document.documentElement.lang : 'en';
  useSSR(deserializedI18nStore, initialLanguage);
}
