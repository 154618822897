import ProxyTypes from '../proxy/ProxyTypes';

const getGooglePlacesSearchUrl = (placeName: any, latLong: any) =>
  `/proxy/${ProxyTypes.GooglePlaces}/search?location=${latLong}&rankby=distance&name=${placeName}`;

const getGooglePlacesPharmacySearchUrl = (latLong: any) =>
  `/proxy/${ProxyTypes.GooglePlaces}/search?location=${latLong}&rankby=distance&type=pharmacy`;

const getGooglePlacesPharmacySearchByNameUrl = (placeName: any, latLong: any) =>
  `/proxy/${ProxyTypes.GooglePlaces}/search?location=${latLong}&rankby=distance&name=${placeName}&type=pharmacy`;

const getGooglePlacesDetailsUrl = (placeId: any) =>
  `/proxy/${ProxyTypes.GooglePlaces}/details?placeid=${placeId}`;

export {
  getGooglePlacesSearchUrl,
  getGooglePlacesPharmacySearchUrl,
  getGooglePlacesPharmacySearchByNameUrl,
  getGooglePlacesDetailsUrl,
};
