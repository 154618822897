import { takeEvery, call, put } from 'redux-saga/effects';
import {
  FETCH_USER_PROFILE_PHOTO,
  saveUserProfilePhoto,
  setFetchingProfileStatus,
} from '../ducks/userProfilePhotos';
import { getProfilePhotoUrl } from '../core/dapi/images';
import { hasProfilePhoto } from '../core/util/account';
import { apiGet, apiGetBlob } from '~/core/dapi';

function* fetchAndSaveProfilePhoto({ userProfile }: any): any {
  if (!hasProfilePhoto(userProfile)) return;

  try {
    yield put(setFetchingProfileStatus(true, userProfile.user_profile_id));
    const url = getProfilePhotoUrl(userProfile);
    const response = yield call(apiGet, url);
    if (!response.image_url) {
      return;
    }

    const blob = yield call(apiGetBlob, response.image_url);

    const photoData = yield new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result);
      reader.onerror = reject;
      reader.readAsDataURL(blob);
    });

    yield put(saveUserProfilePhoto(photoData, userProfile.user_profile_id));
  } catch (e) {
    console.error(e);
    yield put(setFetchingProfileStatus(false, userProfile.user_profile_id));
  }
}

function* rootSaga() {
  yield takeEvery(FETCH_USER_PROFILE_PHOTO, fetchAndSaveProfilePhoto);
}

export { rootSaga as default };
