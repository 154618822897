import Router from 'universal-router';
import history from '~/core/history';
import { isServerSide } from '~/core/util/system';
import routes from '~/routes';

type PartialRoute = {
  path: string;
  parent?: PartialRoute;
};

const router = new Router(routes, {
  resolveRoute: (context) => {
    if (!context?.route) {
      return;
    }
    let fullPath = '';
    function iterate(route: PartialRoute) {
      if (route.parent) {
        iterate(route.parent);
      }
      fullPath += route.path;
    }
    iterate(context.route);
  },
  errorHandler: () => {
    // noop
  },
});

/**
 * Track all the route changes to mixpanel
 * This will be the actual route pathname such as /account/home/:userProfileId/profile
 * instead of the window.location.pathname which can be /account/home/123/profile
 */
export function trackRouteChange() {
  if (isServerSide()) {
    return;
  }
  history.listen((location: any) => {
    router
      .resolve({
        pathname: location.pathname,
      })
      .catch();
  });
  router
    .resolve({
      pathname: window.location.pathname,
    })
    .catch();
}
