import React from 'react';
import { WhiteLabelWrapper } from '~/components/util/WhiteLabelWrapper';
import { safeGet } from '../../core/util/object';

export default {
  path: '/mobile-check-in',
  children: [
    {
      path: '/:bookingId/start',
      get: (context: any) =>
        import(
          /* webpackChunkName: 'mobileCheckIn' */ '../../components/MobileCheckIn/ConnectedMobileCheckInStart'
        ).then((module) => {
          const ConnectedMobileCheckIn = module.default;
          return {
            chunks: ['mobileCheckIn'],

            component: (
              <WhiteLabelWrapper bookingId={context.params.bookingId}>
                <ConnectedMobileCheckIn bookingId={context.params.bookingId} />
              </WhiteLabelWrapper>
            ),
            accountWrapper: {
              returnAfterLogin: false,
              loginRequired: false,
            },
          };
        }),
    },
    {
      path: '/:bookingId/success',
      get: (context: any) =>
        import(
          /* webpackChunkName: 'mobileCheckIn' */ '../../components/MobileCheckIn/ConnectedMobileCheckInSuccess'
        ).then((module) => {
          const ConnectedMobileCheckIn = module.default;
          return {
            chunks: ['mobileCheckIn'],

            component: (
              <WhiteLabelWrapper bookingId={context.params.bookingId}>
                <ConnectedMobileCheckIn bookingId={context.params.bookingId} />
              </WhiteLabelWrapper>
            ),
            accountWrapper: {
              returnAfterLogin: false,
              loginRequired: false,
            },
          };
        }),
    },
    {
      path: '/:bookingId/pre-visit-paperwork',
      get: (context: any) =>
        import(
          /* webpackChunkName: 'mobileCheckIn' */ '../../components/MobileCheckIn/ConnectedPreVisitPaperwork'
        ).then((module) => {
          const ConnectedMobileCheckIn = module.default;
          return {
            chunks: ['mobileCheckIn'],
            component: (
              <WhiteLabelWrapper bookingId={context.params.bookingId}>
                <ConnectedMobileCheckIn
                  bookingId={context.params.bookingId}
                  hasEnded={safeGet(context, false)('query.ended')}
                />
              </WhiteLabelWrapper>
            ),
            accountWrapper: {
              returnAfterLogin: true,
              loginRequired: true,
            },
          };
        }),
    },
    {
      path: '/:bookingId/pre-visit-photo-id-upload',
      get: (context: any) =>
        import(
          /* webpackChunkName: 'mobileCheckIn' */ '../../components/MobileCheckIn/ConnectedPreVisitPhotoIdUpload'
        ).then((module) => {
          const ConnectedMobileCheckIn = module.default;
          return {
            chunks: ['mobileCheckIn'],
            component: (
              <WhiteLabelWrapper bookingId={context.params.bookingId}>
                <ConnectedMobileCheckIn
                  bookingId={context.params.bookingId}
                  hasEnded={safeGet(context, false)('query.ended')}
                />
              </WhiteLabelWrapper>
            ),
            accountWrapper: {
              returnAfterLogin: true,
              loginRequired: true,
            },
          };
        }),
    },
    {
      path: '/:bookingId/pre-visit-payment',
      get: (context: any) =>
        import(
          /* webpackChunkName: 'mobileCheckIn' */ '../../components/MobileCheckIn/ConnectedPreVisitPayment'
        ).then((module) => {
          const ConnectedMobileCheckIn = module.default;
          return {
            chunks: ['mobileCheckIn'],
            component: (
              <WhiteLabelWrapper bookingId={context.params.bookingId}>
                <ConnectedMobileCheckIn
                  bookingId={context.params.bookingId}
                  hasEnded={safeGet(context, false)('query.ended')}
                />
              </WhiteLabelWrapper>
            ),
            accountWrapper: {
              returnAfterLogin: true,
              loginRequired: true,
            },
          };
        }),
    },
    {
      path: '/:bookingId/pre-visit-waitlist',
      get: (context: any) =>
        import(
          /* webpackChunkName: 'mobileCheckIn' */ '../../components/MobileCheckIn/ConnectedPreVisitWaitlist'
        ).then((module) => {
          const ConnectedMobileCheckIn = module.default;
          return {
            chunks: ['mobileCheckIn'],
            component: (
              <WhiteLabelWrapper bookingId={context.params.bookingId}>
                <ConnectedMobileCheckIn
                  bookingId={context.params.bookingId}
                  hasEnded={safeGet(context, false)('query.ended')}
                />
              </WhiteLabelWrapper>
            ),
            accountWrapper: {
              returnAfterLogin: false,
              loginRequired: false,
            },
          };
        }),
    },
  ],
};

if (module.hot) {
  module.hot.accept();
}
