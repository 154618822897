import { call, put, select } from 'redux-saga/effects';
import { setFormData } from '../../../actions/addInsurance';
import { analyticsTrackEvent } from '../../../core/analytics';
import { INSURANCE_TOUCH_NEXT_INSURANCE_COMPLETED } from '../../../core/analytics/events';
import { insuranceFormSubmission } from '../../../actions/insurance';
import { createInsuranceProfileUrl } from '../../../core/dapi/insuranceProfile';
import { apiPost, apiPatch } from '../../../core/dapi';
import { receiveInsuranceProfile } from '../../../actions/insuranceProfile';
import { buildInsuranceProfilePostData, buildNewBookingInsuranceData } from '../util/insuranceForm';
import { ADD_INSURANCE_ORIGIN_USER_PROFILE } from '../util/routing';
import { getUserProfileById } from '../../../core/dapi/userProfile';
import { receiveUserProfileForAccount } from '../../../actions/account';
import { insuranceDetailsSubmitting } from '../../../ducks/insurance/details';
import { safeGet } from '../../../core/util/object';

/**
 * Steps:
 *
 * If adding insurance from account profile:
 * 1. Create Insurance Profile
 * 2. Patch user profile with insurance_profile_id
 * 3. Insurance coverage / PokitDok check
 * (send userProfileId to automatically do user profile patch with insurance profile)
 *
 * If adding insurance during booking (booking widget or app booking):
 * 1. Insurance coverage / PokitDock check
 * No need to do anything else here, since submitting the booking later
 * will take care of insurance profile and user profile updates
 */
export function* submitInsuranceForm({ flow, form, location, currentUser }: any): any {
  analyticsTrackEvent(INSURANCE_TOUCH_NEXT_INSURANCE_COMPLETED);
  const getMarketplaceInsurersFromState = (state: any) => {
    const safeState = safeGet(state);
    return safeState('marketplaceInsurers.insurers') || [];
  };

  const marketplaceInsurers = yield select(getMarketplaceInsurersFromState);
  const newBookingInsuranceData = buildNewBookingInsuranceData(
    form,
    marketplaceInsurers,
    currentUser.userProfileId,
    currentUser.accountId
  );
  yield put(insuranceFormSubmission(newBookingInsuranceData));
  yield put(setFormData(form));

  let newInsuranceProfileId = null;

  if (flow === ADD_INSURANCE_ORIGIN_USER_PROFILE) {
    const { accountId } = currentUser;
    const { userProfileId } = currentUser;
    yield put(insuranceDetailsSubmitting(true));

    const insuranceProfileUrl = createInsuranceProfileUrl();
    const userProfilePatchUrl = getUserProfileById(userProfileId);

    const insuranceProfilePostData = buildInsuranceProfilePostData(
      form,
      accountId,
      marketplaceInsurers
    );

    const createInsuranceProfileResponse = yield call(
      // @ts-expect-error ts-migrate(2769) FIXME: Type '(url: any, data: any, options: any) => any' ... Remove this comment to see the full error message
      apiPost,
      insuranceProfileUrl,
      insuranceProfilePostData
    );
    yield put(
      receiveInsuranceProfile({
        ...createInsuranceProfileResponse,
        insurance_profile_id: createInsuranceProfileResponse.id,
      })
    );

    newInsuranceProfileId = createInsuranceProfileResponse.id;

    const userProfilePatchData = {
      dental_insurance_profile_id: newInsuranceProfileId,
    };
    const userProfilePatchResponse = yield call(
      // @ts-expect-error ts-migrate(2769) FIXME: Type '(url: any, data: any, options: any) => any' ... Remove this comment to see the full error message
      apiPatch,
      userProfilePatchUrl,
      userProfilePatchData
    );
    yield put(receiveUserProfileForAccount(userProfilePatchResponse));
  }
  yield put(insuranceDetailsSubmitting(false));
}
