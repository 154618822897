import { delay } from 'redux-saga';
import Location from '@solvhealth/types/interfaces/Location';
import { call, put, race, spawn } from 'redux-saga/effects';
import { Action } from 'redux';
import { OcrData } from '@solvhealth/types/interfaces/OcrData';
import { ACUANT_TIMEOUT, INSURANCE_COVERAGE_TIMEOUT } from '../../../config/index';
import {
  ACUANT_WEB_DESCRIPTIONS,
  acuantCropUrl,
  acuantNoCropUrl,
  acuantPostImage,
  acuantReformattedResponseToBase64,
  buildAcuantData,
  mapAcuantInsurerToStandardType,
  mapAcuantToFields,
  RESPONSE_CODE_PROC_MEDICAL_CARD_DESC,
} from '../../../core/acuant';
import { getAccountSummaryById } from '../../../core/dapi/account';
import logger from '../../../core/logger/index';
import { setFormData } from '../../../actions/addInsurance';
import { analyticsTrackEvent } from '../../../core/analytics';
import { getTimeBucket } from '../../../core/analytics/utils';
import {
  INSURANCE_ACUANT_FAILED,
  INSURANCE_ACUANT_SCAN_COMPLETE,
  INSURANCE_TOUCH_NEXT_INSURANCE_COMPLETED,
} from '../../../core/analytics/events';
import { insuranceFormSubmission } from '../../../actions/insurance';
import { POKITDOK_CODE_URGENT_CARE, SUBMIT_INSURANCE_FORM } from '../../../constants/index';
import {
  createInsuranceProfileUrl,
  getInsuranceDetailsUrl,
} from '../../../core/dapi/insuranceProfile';
import { apiGet, apiPatch, apiPost } from '../../../core/dapi';
import {
  npiCheckError as insuranceCoverageError,
  npiCheckReceived as insuranceCoverageReceived,
  npiCheckSubmitted as insuranceCoverageSubmitted,
} from '../../../actions/npi';
import { getNpiCheckUrl as getInsuranceCoverageUrl } from '../../../core/dapi/npi';
import { getInsurersListUrl } from '../../../core/dapi/insurers';
import { insurersError, insurersReceived } from '../../../actions/insurers';
import { receiveInsuranceProfile } from '../../../actions/insuranceProfile';
import {
  buildInsuranceCoveragePostData,
  buildInsuranceProfilePostData,
  buildNewBookingInsuranceData,
  InsuranceFormShape,
} from '../util/insuranceForm';
import {
  AddInsuranceOrigins,
  ADD_INSURANCE_ORIGIN_USER_PROFILE,
} from '../../../core/util/bookingFlowRouting';
import { getUserProfileById } from '../../../core/dapi/userProfile';
import { receiveAccountSummary, receiveUserProfileForAccount } from '../../../actions/account';
import {
  insuranceDetailsReceived,
  insuranceDetailsSubmitting,
} from '../../../ducks/insurance/details';
import { ImageType, uploadImageSaga, UploadImageSagaAction } from '../../../sagas/images';
import { getCurrentUser } from '~/core/util/currentUser';
import { InsurersState } from '~/reducers/insurers';

type SubmitInsuranceFormParams = {
  type: typeof SUBMIT_INSURANCE_FORM;
  flow: AddInsuranceOrigins;
  form: InsuranceFormShape;
  location: Location;
  currentUser: ReturnType<typeof getCurrentUser>;
  insurers: InsurersState;
};

/**
 * Steps:
 *
 * If adding insurance from account profile:
 * 1. Create Insurance Profile
 * 2. Patch user profile with insurance_profile_id
 * 3. Insurance coverage / PokitDok check
 * (send userProfileId to automatically do user profile patch with insurance profile)
 *
 * If adding insurance during booking (booking widget or app booking):
 * No need to do anything else here, since submitting the booking later
 * will enqueue the insurance coverage / PokitDock check
 * and will take care of insurance profile and user profile updates
 */
export function* submitInsuranceForm({
  flow,
  form,
  location,
  currentUser,
  insurers,
}: SubmitInsuranceFormParams): any {
  analyticsTrackEvent(INSURANCE_TOUCH_NEXT_INSURANCE_COMPLETED);
  const newBookingInsuranceData = buildNewBookingInsuranceData(
    form,
    insurers,
    currentUser.userProfileId,
    currentUser.accountId
  );
  yield put(insuranceFormSubmission(newBookingInsuranceData));
  yield put(setFormData(form));

  let newInsuranceProfileId = null;

  yield put(insuranceDetailsSubmitting(true));
  if (flow === ADD_INSURANCE_ORIGIN_USER_PROFILE) {
    const { accountId } = currentUser;
    const { userProfileId } = currentUser;

    const insuranceProfileUrl = createInsuranceProfileUrl();
    const userProfilePatchUrl = getUserProfileById(userProfileId);

    const insuranceProfilePostData = yield call(
      buildInsuranceProfilePostData,
      form,
      accountId,
      insurers
    );

    const createInsuranceProfileResponse = yield call(
      // @ts-ignore
      apiPost,
      insuranceProfileUrl,
      insuranceProfilePostData
    );
    yield put(receiveInsuranceProfile(createInsuranceProfileResponse));

    newInsuranceProfileId = createInsuranceProfileResponse.id;

    const userProfilePatchData = {
      insurance_profile_id: newInsuranceProfileId,
    };
    const userProfilePatchResponse = yield call(
      // @ts-ignore
      apiPatch,
      userProfilePatchUrl,
      userProfilePatchData
    );
    yield put(receiveUserProfileForAccount(userProfilePatchResponse));
  }

  try {
    const { userProfileId } = currentUser;

    if (flow === ADD_INSURANCE_ORIGIN_USER_PROFILE) {
      const insuranceCoverageUrl = getInsuranceCoverageUrl();
      const insuranceCoveragePostData = buildInsuranceCoveragePostData(
        form,
        location,
        insurers,
        userProfileId,
        newInsuranceProfileId
      );

      yield put(insuranceCoverageSubmitted(location));
      let { response, timeout } = yield race({
        // @ts-ignore
        response: call(apiPost, insuranceCoverageUrl, insuranceCoveragePostData),
        timeout: delay(INSURANCE_COVERAGE_TIMEOUT),
      });
      if (timeout) {
        throw Error('insurance coverage timeout');
      }

      const insuranceCoverageResponse = response;
      yield put(insuranceCoverageReceived(insuranceCoverageResponse));
      if (currentUser && currentUser.accountId) {
        const accountSummaryUrl = getAccountSummaryById(currentUser.accountId);
        const updatedAccountSummary = yield call(apiGet, accountSummaryUrl);
        yield put(receiveAccountSummary(updatedAccountSummary));
      }
      if (
        insuranceCoverageResponse &&
        insuranceCoverageResponse.eligibility_requests_id &&
        newInsuranceProfileId
      ) {
        const insuranceDetailsUrl = getInsuranceDetailsUrl(
          insuranceCoverageResponse.eligibility_requests_id,
          newInsuranceProfileId,
          [POKITDOK_CODE_URGENT_CARE]
        );
        const insuranceDetails = yield call(apiGet, insuranceDetailsUrl);
        yield put(insuranceDetailsReceived(insuranceDetails, userProfileId));
      }
    } else {
      yield put(insuranceCoverageReceived({}));
      yield put(insuranceDetailsReceived({}, userProfileId));
    }
  } catch (e) {
    yield put(insuranceCoverageError(e));
  } finally {
    yield put(insuranceDetailsSubmitting(false));
  }
}

export function* fetchInsurersList({ stateCode }: any): any {
  try {
    const fetchInsurersListUrl = getInsurersListUrl(stateCode);
    const insurersListResponse = yield call(apiGet, fetchInsurersListUrl);
    yield put(insurersReceived(insurersListResponse));
  } catch (e) {
    yield put(insurersError(e));
  }
}

export interface AcuantPostSagaAction extends Action {
  imageType: ImageType;
  imageFile: File;
  onStart: () => Action;
  onStop: (error: boolean) => Action;
  setImageData: ((data: string) => Action)[];
  setImageId: ((id: string) => Action)[];
  setOcrData: (data: OcrData) => Action;
  trackingProperties: {
    locationId: string;
    packageName: string;
    groupId: string;
    type: string;
  };
}

export function* acuantPost({
  imageType,
  imageFile,
  onStart,
  onStop,
  setImageData,
  setImageId,
  setOcrData,
  trackingProperties,
}: AcuantPostSagaAction) {
  const startTime = new Date().getTime();
  const fileSize = imageFile.size;
  let resubmitWithoutCrop = false;

  try {
    // first upload to s3 so that in any event we can save the image the user uploaded
    yield spawn(uploadImageSaga, {
      imageType,
      imageFile,
      setImageData,
      setImageId,
      shouldReorient: true,
    } as UploadImageSagaAction);

    // post to acuant
    yield put(onStart());

    const postData = buildAcuantData(imageFile);
    let { response, timeout } = yield race({
      response: call(acuantPostImage, acuantCropUrl, postData),
      timeout: delay(ACUANT_TIMEOUT),
    });

    if (timeout) throw Error('acuant timeout');
    if (
      response.ResponseCodeProcMedicalCardDesc ===
      RESPONSE_CODE_PROC_MEDICAL_CARD_DESC.EXTRACTION_FAILED
    )
      throw Error('Acuant text extraction failed');

    /* Sometimes acuant is unable to crop the image, but still able to get useful data
     * by resubmitting but not asking for them to crop. Why they don't just return the
     * data along with that error I'll never know, but this is the solution.
     * If you're reading this as a fresh developer in the year 2034, then good luck. */
    if (response.WebResponseDescription === ACUANT_WEB_DESCRIPTIONS.unableToCrop) {
      let { unCroppedResponse, unCroppedTimeout } = yield race({
        unCroppedResponse: call(acuantPostImage, acuantNoCropUrl, postData),
        unCroppedTimeout: delay(ACUANT_TIMEOUT),
      });

      if (unCroppedTimeout) throw Error('acuant timeout');

      response = unCroppedResponse;
      resubmitWithoutCrop = true;
    } else {
      // receive formattedImage and put in state
      const reformattedImage = acuantReformattedResponseToBase64(response.ReformattedImage);

      yield spawn(uploadImageSaga, {
        imageType,
        imageFile: reformattedImage,
        setImageData,
        setImageId,
        shouldReorient: false,
      } as UploadImageSagaAction);
    }

    const msTimeDelta = new Date().getTime() - startTime;
    analyticsTrackEvent(INSURANCE_ACUANT_SCAN_COMPLETE, {
      msTimeDelta,
      fileSizeBytes: fileSize,
      resubmitWithoutCrop,
      timeBucket: getTimeBucket(msTimeDelta),
      ...trackingProperties,
    });

    yield put(onStop(false));

    // reformat response and send to state
    const mapped = mapAcuantToFields(response);
    mapped.insuranceType = mapAcuantInsurerToStandardType(mapped);

    yield put(setOcrData(mapped));
  } catch (e: any) {
    logger.error(e);
    yield put(onStop(true));
    const msTimeDelta = new Date().getTime() - startTime;
    analyticsTrackEvent(INSURANCE_ACUANT_FAILED, {
      jsError: e,
      msTimeDelta,
      fileSizeBytes: fileSize,
      resubmitWithoutCrop,
      timeBucket: getTimeBucket(msTimeDelta),
      ...trackingProperties,
    });
  }
}
