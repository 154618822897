import { DAPI_HOST } from '../../config/index';

export const getAbTestingGroupAssignmentUrl = (groupOptions: any, probabilityWeights: any) => {
  const options = groupOptions.join(',');
  const weights = probabilityWeights.join(',');

  if (weights) return `${DAPI_HOST}/v1/ab-test-group?group_options=${options}&weights=${weights}`;

  return `${DAPI_HOST}/v1/ab-test-group?group_options=${options}`;
};
