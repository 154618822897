import { takeLatest, takeEvery } from 'redux-saga/effects';
import {
  acuantPost,
  submitInsuranceForm,
  fetchInsurersList,
} from '../components/AddInsurance/sagas/insuranceUpload';
import { submitInsuranceForm as submitDentalInsuranceForm } from '../components/AddDentalInsurance/sagas/insuranceUpload';
import {
  ACUANT_POST,
  FETCH_INSURERS_LIST,
  SUBMIT_DENTAL_INSURANCE_FORM,
  SUBMIT_INSURANCE_FORM,
} from '../constants/index';

function* rootSaga() {
  yield takeLatest(FETCH_INSURERS_LIST, fetchInsurersList);
  yield takeLatest(SUBMIT_INSURANCE_FORM, submitInsuranceForm);
  yield takeLatest(SUBMIT_DENTAL_INSURANCE_FORM, submitDentalInsuranceForm);
  yield takeEvery(ACUANT_POST, acuantPost); // takeEvery because simultaneous uploads can happen
}

export { rootSaga as default };
