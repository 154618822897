import React from 'react';

export const REDIR = '/redir';

export default {
  path: REDIR,
  get: ({ query }: any) =>
    import(/* webpackChunkName: 'redir' */ '../../components/Redir').then((module) => ({
      component: <module.default nextPath={query.n} stepsBackwards={query.sb} />,
      accountWrapper: {
        loginRequired: false,
        nonBlocking: true,
      },
    })),
};
