import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Cookies, withCookies } from 'react-cookie';
import moment from 'moment-timezone';
import { ErrorWithCause } from 'pony-cause';
import { isEmptyObject } from '../../core/util/object';
import LoadingScreen from '../SolvPatternLibrary/LoadingScreen';
import TosUpdate from '../Account/TosUpdate';
import {
  accountSummaryError,
  accountSummaryLoading,
  receiveAccountSummary,
} from '../../actions/account';
import { accountFetching, resetLoginData } from '../../actions/login';
import { apiGetDispatchable, apiPostDispatchable } from '../../core/dapi';
import { getAccountSummaryById, setPinkDoorTosAccepted } from '../../core/dapi/account';
import history from '../../core/history';
import { getAccountId } from '../../core/auth';
import { clearUserInfo } from '../../core/session';
import { noOp } from '../../actions';
import { FETCH_LOGIN_ACCOUNT } from '../../sagas/account';
import {
  CROSSED_PINK_DOOR,
  CROSSED_PINK_DOOR_TYPE_ACCEPTED_TOS_ACCOUNT,
  LOGIN_PERSISTED,
} from '../../core/analytics/events';
import { analyticsTrackEvent } from '../../core/analytics';
import logger from '../../core/logger/index';
import withAuthentication from '../../core/login/withAuthentication';
import {
  LOGIN_RESPONSE_MAX_ATTEMPTS_EXCEEDED,
  LOGIN_RESPONSE_WRONG_OTP,
} from '../../constants/phoneLoginForm';
import {
  didCrossPinkdoorAfterTimeSpecified,
  getSolvUserDate,
  isPinkDoorExceptionUrl,
} from '../../core/util/account';
import { isAndroidApp, isAndroidOS, isIOS, isIosApp } from '~/core/util/device';
import { ACCEPTED_NEW_TOS_COOKIE_NAME } from '~/config';
import { setPinkDoorTosWithDispatch } from '~/core/util/tos';

const mapStateToProps = (state: any) => ({
  accountSummary: state.account.summary,
  accountSummaryError: state.account.accountSummaryError,
  isAccountSummaryLoading: state.account.accountSummaryLoading || false,
  loginAccount: state.login.account,
  loginLogin: state.login.login,
  persistentStateLoaded: state.runtime.persistentStateLoaded,
  loginAccountFetching: state.login.fetching || false,
  requiresTosConsent: !!state.runtime.requiresTosConsent && !isPinkDoorExceptionUrl(),
  loginAccountError: !!state.login.accountError,
});

const mapDispatchToProps = (dispatch: any) => ({
  fetchAccountSummary: (accountId: any, isAccountSummaryLoading: any) => {
    if (isAccountSummaryLoading) return;
    const onError = (errorMessage: Error) => {
      logger.error(new ErrorWithCause('Error fetching account summary', { cause: errorMessage }));
      return accountSummaryError(errorMessage);
    };

    dispatch(accountSummaryLoading());
    const url = getAccountSummaryById(accountId);
    dispatch(apiGetDispatchable(url, receiveAccountSummary, onError));
  },

  fetchLoginAccount: (accountId: any) => {
    // @ts-expect-error ts-migrate(2554) FIXME: Expected 1 arguments, but got 0.
    dispatch(accountFetching());
    dispatch({
      type: FETCH_LOGIN_ACCOUNT,
      accountId,
    });
  },

  setTosAccepted: (accountId: any) => {
    setPinkDoorTosWithDispatch(accountId, dispatch);
  },

  clearLogin: (cookies: any) => {
    dispatch(resetLoginData());
    clearUserInfo(cookies);
  },
});

type OwnWrapperProps = {
  accountSummary?: any;
  accountSummaryError?: string;
  clearLogin: (...args: any[]) => any;
  loginLogin?: any;
  fetchAccountSummary?: (...args: any[]) => any;
  isAccountSummaryLoading?: boolean;
  loginAccount?: any;
  loginRequired?: boolean;
  persistentStateLoaded?: boolean;
  nonBlocking?: boolean;
  returnAfterLogin?: boolean;
  setTosAccepted: (accountId?: string) => void;
  loginAccountFetching?: boolean;
  fetchLoginAccount?: (...args: any[]) => any;
  isAuthorized: boolean;
  isLoggedIn: boolean;
  cookies?: any;
  requiresTosConsent?: boolean;
  loginAccountError?: boolean;
};

type WrapperProps = OwnWrapperProps & typeof Wrapper.defaultProps;

export class Wrapper extends Component<WrapperProps> {
  state = {
    hasAcceptedTos: false,
  };

  // Add a new method to set Tos accepted state
  handleTosAccepted = async (accountId: any) => {
    await this.props.setTosAccepted(accountId);
    this.setState({ hasAcceptedTos: true });
    new Cookies().set(ACCEPTED_NEW_TOS_COOKIE_NAME, true, {
      expires: new Date(+new Date() + 86400000),
      path: '/',
    });
  };

  static defaultProps = {
    loginRequired: true,
    nonBlocking: false,
    returnAfterLogin: true,
  };

  hasTrackedPersistedLogin: any;

  constructor(props: WrapperProps) {
    super(props);
    this.hasTrackedPersistedLogin = false;
  }

  componentDidMount = () => {
    const { isAuthorized } = this.props;
    if (isAuthorized && history.length === 0) {
      const accountId = getAccountId();
      this.trackPersistedLogin(accountId);
    }
    this.handleAccountData();
  };

  componentDidUpdate = (prevProps: any) => {
    if (this.didJustLogOut(prevProps)) {
      this.handleAccountError();
    }
    if (this.accountDataFetchingFailed(prevProps)) {
      const { loginLogin } = this.props;
      if (
        !(
          loginLogin &&
          (loginLogin.auth === LOGIN_RESPONSE_WRONG_OTP ||
            loginLogin.auth === LOGIN_RESPONSE_MAX_ATTEMPTS_EXCEEDED)
        )
      )
        this.handleAccountError();
    }
    this.handleAccountData();
  };

  accountDataFetchingFailed = (prevProps: any) => {
    const { accountSummaryError, loginAccountError } = this.props;
    return (
      (!prevProps.accountSummaryError && accountSummaryError) ||
      (!prevProps.loginAccountError && loginAccountError)
    );
  };

  // For pages that required login (loginRequired), we never know if they'll need loginAccount or accountSummary (at least historically, some neeed one, some the other, some both).
  // So, going forward, we're going to mark this component as in loading state until both these slices of data are loaded
  hasAllAccountData = (loginAccount: any, accountSummary: any) =>
    !isEmptyObject(loginAccount) && !isEmptyObject(accountSummary);

  // We use this function to determine if there's any account data loaded in state (either in memory or coming from session storage), while the user doesn't have a valid authorization (invalid token or no token).
  hasSomeAccountData = (loginAccount: any, accountSummary: any) =>
    !isEmptyObject(loginAccount) || !isEmptyObject(accountSummary);

  didJustLogIn = (prevProps: any) => !prevProps.isLoggedIn && this.props.isLoggedIn;

  didJustLogOut = (prevProps: any) => prevProps.isLoggedIn && !this.props.isLoggedIn;

  isAlreadyOnLoginPage = () => {
    if (typeof window === 'undefined') return false;
    /**
     * This regex tests if we are ony any login route already:
     *
     * ^                -- Match at the beginning of the path
     * (?:\/account)?   -- Optionally match `/account`
     * \/login          -- match `/login`
     * (?:\/next)?      -- Optionally match `/next`
     */
    return /^(?:\/account)?\/login(?:\/next)?/.test(window.location.pathname);
  };

  handleAccountError = () => {
    // We don't need to be redirecting the user again if they are already on a login page!
    if (this.isAlreadyOnLoginPage()) {
      return;
    }

    const { clearLogin, loginRequired, returnAfterLogin, cookies } = this.props;
    clearLogin(cookies);

    if (loginRequired) {
      if (returnAfterLogin) {
        const { pathname, search } = window.location;
        history.replace(`/account/login/next${pathname}${search}`);
      } else {
        history.replace('/account/login');
      }
    }
  };

  handleAccountData = () => {
    const {
      loginAccount,
      fetchAccountSummary,
      isAccountSummaryLoading,
      loginAccountFetching,
      fetchLoginAccount,
      accountSummary,
      isAuthorized,
      loginRequired,
      requiresTosConsent,
    } = this.props;

    if (isAuthorized && !requiresTosConsent) {
      const accountId = getAccountId();
      if (!accountId) {
        return this.handleAccountError();
      }

      if (isEmptyObject(accountSummary)) {
        // @ts-expect-error ts-migrate(2722) FIXME: Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message
        fetchAccountSummary(accountId, isAccountSummaryLoading, this.handleAccountError);
      }

      if (isEmptyObject(loginAccount) && !loginAccountFetching) {
        // @ts-expect-error ts-migrate(2722) FIXME: Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message
        fetchLoginAccount(accountId);
      }
    }
    if (!isAuthorized && (loginRequired || this.hasSomeAccountData(loginAccount, accountSummary))) {
      return this.handleAccountError();
    }

    return null;
  };

  isLoading = () => {
    const { nonBlocking, persistentStateLoaded, loginAccount, loginRequired, accountSummary } =
      this.props;

    if (nonBlocking) {
      return false;
    }
    if (!persistentStateLoaded) {
      return true;
    }

    return loginRequired && !this.hasAllAccountData(loginAccount, accountSummary);
  };

  trackPersistedLogin = (accountId: any) => {
    if (!this.hasTrackedPersistedLogin) {
      analyticsTrackEvent(LOGIN_PERSISTED, { accountId });
      this.hasTrackedPersistedLogin = true;
    }
  };

  render = () => {
    if (this.isLoading()) {
      return <LoadingScreen />;
    }
    const { accountSummary, clearLogin } = this.props;
    const { hasAcceptedTos } = this.state;
    const alreadyAcceptedTOS = hasAcceptedTos || new Cookies().get(ACCEPTED_NEW_TOS_COOKIE_NAME);

    // @ts-expect-error ts-migrate(2769) FIXME: Type 'undefined' is not assignable to type 'ReactE... Remove this comment to see the full error message
    const content = React.cloneElement(this.props.children, { accountSummary });

    if (
      accountSummary?.requires_tos_consent_update &&
      !alreadyAcceptedTOS &&
      !isPinkDoorExceptionUrl()
    ) {
      return (
        <div>
          {content}

          <TosUpdate
            accountId={accountSummary.id}
            clearLogin={() => clearLogin(this.props.cookies)}
            setTosAccepted={this.handleTosAccepted}
          />
        </div>
      );
    }

    return content;
  };
}

export default withAuthentication(
  // @ts-expect-error ts-migrate(2345) FIXME: Type 'ReactCookieProps & { children?: ReactNode; }... Remove this comment to see the full error message
  withCookies(connect(mapStateToProps, mapDispatchToProps)(Wrapper))
);
