import React, { PureComponent } from 'react';
import Notifications from './Notifications';
import NotificationsContext from './NotificationsContext';

export type NotificationsProviderProps = {
  swRegistration?: any;
  applicationServerKey?: string;
};

type State = any;

export default class NotificationsProvider extends PureComponent<
  NotificationsProviderProps,
  State
> {
  _isMounted: any;

  removeListener: any;

  constructor(props: NotificationsProviderProps) {
    super(props);

    this.removeListener = null;

    this.state = {
      notificationsSupported: false,
      notificationsSubscription: null,
      notifications: {},
    };
  }

  componentDidMount() {
    this._isMounted = true;
    this.setup();
  }

  componentDidUpdate(prevProps: NotificationsProviderProps) {
    const { swRegistration, applicationServerKey } = this.props;

    if (
      swRegistration !== prevProps.swRegistration ||
      applicationServerKey !== prevProps.applicationServerKey
    ) {
      this.setup();
    }
  }

  componentWillUnmount() {
    this._isMounted = false;

    if (this.removeListener) {
      this.removeListener();
    }
  }

  setup() {
    const { swRegistration, applicationServerKey } = this.props;

    if (swRegistration) {
      swRegistration
        .then(async (swReg: any) => {
          if (!this._isMounted) {
            return;
          }

          if (this.removeListener) {
            this.removeListener();
            this.removeListener = null;
          }

          const notifications = new Notifications(swReg.pushManager, applicationServerKey);
          notifications.listen();
          this.removeListener = notifications.onSubscriptionChange((newSubscription: any) => {
            this.setState({
              notificationsSubscription: newSubscription,
            });
          });

          const subscription = await notifications.getSubscription();

          this.setState({
            notificationsSupported: true,
            notificationsSubscription: subscription,
            notifications,
          });
        })
        .catch(() => {}); // silently fail in browsers that don't use service-workers
    }
  }

  render() {
    return (
      <NotificationsContext.Provider value={this.state}>
        {this.props.children}
      </NotificationsContext.Provider>
    );
  }
}
