import { all, call, put, takeEvery } from 'redux-saga/effects';
import { apiPost } from '../core/dapi';
import { getAddBackupTimeUrl } from '../core/dapi/newBooking';

export class AddBackupTimesSagas {
  static ADD_BACKUP_TIMES = 'SAGA/ADD_BACKUP_TIMES';
}

function* addBackupTimesHelper({ bookingId, backupTimes, locationId, onSuccess }: any) {
  yield all(
    backupTimes.map((time: any) =>
      // @ts-expect-error ts-migrate(2769) FIXME: Type '(url: any, data: any, options: any) => any' ... Remove this comment to see the full error message
      call(apiPost, getAddBackupTimeUrl(), {
        booking_id: bookingId,
        backup_appointment_date: time,
      })
    )
  );

  onSuccess();
}

export default function* addBackupTimes() {
  yield takeEvery(AddBackupTimesSagas.ADD_BACKUP_TIMES, addBackupTimesHelper);
}
