import useScript from '~/hooks/useScript';
import { Intercom } from '~/core/intercom/intercomChat';

/**
 * Asynchronously include load the Intercom script (at most one time)
 */
export function useIntercomScript(onLoad: () => any, appId: string) {
  useScript({
    src: `https://widget.intercom.io/widget/${appId}`,
    testIfLoaded() {
      return Intercom.isLoaded();
    },
    onLoad,
  });
}
