import mergeWith from 'lodash/mergeWith';
import { AnyAction, applyMiddleware, compose, createStore } from 'redux';
import { persistReducer } from 'redux-persist';
import storageSession from 'redux-persist/lib/storage/session';
import createSagaMiddleware from 'redux-saga';
import thunk from 'redux-thunk';
import { v1 as timeBasedUuid } from 'uuid';
import { getDefaultViewport } from '../core/util/device/screen';
import { EMPTY_OBJECT, isEmptyObject } from '../core/util/object';
import { isClientSide } from '../core/util/system';
import rootReducer, { SolvReduxState } from '../reducers';
import rootSaga from '../sagas';

const sagaMiddleware = createSagaMiddleware();
const middleware = [thunk, sagaMiddleware];

let enhancer: any;

if (typeof __DEV__ !== 'undefined' && __DEV__ && process.env.BROWSER) {
  const createLogger = require('redux-logger');
  const logger = createLogger({
    collapsed: true,
  });
  middleware.push(logger);

  enhancer = compose(
    applyMiddleware(...middleware),

    // https://github.com/zalmoxisus/redux-devtools-extension#usage
    // @ts-expect-error ts-migrate(2339) FIXME: Property '__REDUX_DEVTOOLS_EXTENSION__' does not e... Remove this comment to see the full error message
    window.__REDUX_DEVTOOLS_EXTENSION__ ? window.__REDUX_DEVTOOLS_EXTENSION__() : (f: any) => f
  );
} else {
  enhancer = compose(applyMiddleware(...middleware));
}

const startUsingStore = (store: any) => {
  rootSaga.forEach((saga) => sagaMiddleware.run(saga));
};

/**
 * redux-persist's off-the-shelf merge strategies do not include a deep merge of redux store *ONTO*
 * the data in the persisted store.
 *
 * @param inboundState this is what's coming from the persisted store
 * @param originalState this is what's in the redux store before the `persist/REHYDRATE` action
 * @param reducedState this is what's in the redux store after the `persist/REHYDRATE` action
 */
const deepMergeReconcile = (inboundState: any, originalState: any, reducedState: any) =>
  mergeWith(inboundState, reducedState, (inboundStateValue: any, reducedStateValue: any) =>
    isEmptyObject(reducedStateValue) ? inboundStateValue : reducedStateValue
  );

/**
 * @param initialState
 */
export default function configureStore(initialState: any) {
  if (isClientSide()) {
    const doNotPersist = [
      'account',
      'leaveAReview',
      'runtime',
      'waitList',
      'content',
      'videoVisit',
      'searchPreferences',
      'session',
    ];

    const persistConfig = {
      key: 'root',
      blacklist: doNotPersist,
      storage: storageSession,
      stateReconciler: deepMergeReconcile,
    };

    // Note: only Redux >= 3.1.0 supports passing enhancer as third argument.
    // See https://github.com/rackt/redux/releases/tag/v3.1.0
    const persistedReducer = persistReducer<SolvReduxState, AnyAction>(persistConfig, rootReducer);
    const store = createStore<SolvReduxState>(persistedReducer, initialState, enhancer);
    startUsingStore(store);

    return { store };
  }

  const store = createStore<SolvReduxState>(rootReducer, initialState, enhancer);
  startUsingStore(store);

  return { store };
}

export const getServerInitialState = (userAgent?: string) => ({
  runtime: {
    ajax: {},
    initialNow: Date.now(),
    shortTermSessionID: timeBasedUuid(),
    viewport: getDefaultViewport(userAgent),
  },
});

export const getClientInitialState = () => {
  const srcElm = document.getElementById('source');
  let initialState;

  if (srcElm) {
    initialState = srcElm.getAttribute('data-initial-state');
  }

  return initialState ? JSON.parse(initialState) : EMPTY_OBJECT;
};

if (module.hot) {
  module.hot.accept();
}
