import { INSURANCE_NAME_ID } from '../../Search/components/Filters/Fields/ids';

const fields = {
  dobYear: { id: 'dobYear', label: 'Year' },
  dobMonth: { id: 'dobMonth', label: 'Birthdate: Month' },
  dobDay: { id: 'dobDay', label: 'Day' },
  insuranceCarrier: { id: 'insuranceCarrier', label: 'Insurance Carrier' },
  insuranceType: { id: 'insuranceType', label: 'Insurance Type' },
  memberId: { id: 'memberId', label: 'Member ID' },
  firstName: { id: 'firstName', label: 'First Name' },
  lastName: { id: 'lastName', label: 'Last Name' },
};

const buildInsuranceProfilePostData = (insuranceForm = {}, accountId: any, insurers = []) => {
  // @ts-expect-error ts-migrate(2339) FIXME: Property 'id' does not exist on type 'never'.
  const insurer = insurers.find((insurer) => insurer.id === insuranceForm[INSURANCE_NAME_ID]);
  // @ts-expect-error ts-migrate(2339) FIXME: Property 'name' does not exist on type 'never'.
  const insurerName = insurer ? insurer.name : '';

  return {
    account_id: accountId,
    // @ts-expect-error ts-migrate(7053) FIXME: Property 'insurance-name-field' does not exist on ... Remove this comment to see the full error message
    marketplace_insurers_id: insuranceForm[INSURANCE_NAME_ID],
    insurer_name: insurerName,
    insurance_type: 'dental',
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'cardFront' does not exist on type '{}'.
    card_front_id: insuranceForm.cardFront,
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'cardBack' does not exist on type '{}'.
    card_back_id: insuranceForm.cardBack,
  };
};

const buildNewBookingInsuranceData = (
  insuranceForm = {},
  insurers = [],
  userProfileId = null,
  accountId = null
) => {
  // @ts-expect-error ts-migrate(2339) FIXME: Property 'id' does not exist on type 'never'.
  const insurer = insurers.find((insurer) => insurer.id === insuranceForm[INSURANCE_NAME_ID]);
  // @ts-expect-error ts-migrate(2339) FIXME: Property 'name' does not exist on type 'never'.
  const insurerName = insurer ? insurer.name : '';

  return {
    insurerName,
    // @ts-expect-error ts-migrate(7053) FIXME: Property 'insurance-name-field' does not exist on ... Remove this comment to see the full error message
    marketplace_insurers_id: insuranceForm[INSURANCE_NAME_ID],
    insuranceType: 'dental',
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'cardFront' does not exist on type '{}'.
    cardFront: insuranceForm.cardFront,
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'cardBack' does not exist on type '{}'.
    cardBack: insuranceForm.cardBack,
    userProfileId,
    accountId,
  };
};

export { fields, buildInsuranceProfilePostData, buildNewBookingInsuranceData };
